import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react'

const MergingNaming = ({ defaultFromName, handleChange, titleSearchedData, fromNameValue, toNameValue, fromNameOptions, toNameOptions, handleAddMergeName, error }) => {
    return (
      <Grid item container spacing={2} sx={{ scroll: "auto" }}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={fromNameOptions || []}
            id="combo-box-demo"
            name="From Name"
            value={defaultFromName?.title || null}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                focused
                label="From Name"
                name="From Name"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                }}
                onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
              />
            )}
            onInputChange={(e, newInputValue) => {
              handleChange("From Name", newInputValue);
            }}
            disabled
            style={{ width: "100%", marginTop: 4 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={toNameOptions || []}
            id="combo-box-demo"
            name="To Name"
            value={toNameValue || null}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                focused
                label="To Name"
                name="To Name"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                }}
                onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
              />
            )}
            onInputChange={(e, newInputValue) => {
              handleChange("To Name", newInputValue);
            }}
            style={{ width: "100%", marginTop: 4 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='caption' color={'error'}>{!toNameValue || toNameValue === null ? error['toName'] : defaultFromName?.title === toNameValue ? error['Names'] : ""}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"center"} mt={5}>
            <Button variant="contained" onClick={handleAddMergeName}>
              Merge
            </Button>
          </Box>
        </Grid>
      </Grid>
    )
  } 

export default MergingNaming
