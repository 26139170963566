import { Box, Chip, Divider } from "@mui/material";

const DualValueChip = ({ transactionType, handleTransactionType }) => {

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <Chip
        label={"Income (Credit)"}
        clickable
        color={transactionType === 0 ? "primary" : "default"}
        onClick={() => handleTransactionType(0)}
        style={{ borderRadius: "20px 0 0 20px", height: '40px', width: '150px', fontSize: '15px' }}
        
      />
      <Chip
        label={"Expense (Debit)"}
        clickable
        color={transactionType === 1 ? "primary" : "default"}
        onClick={() =>
          handleTransactionType(1)
        }
        style={{ borderRadius: "0 20px 20px 0", height: '40px', width: '150px', fontSize: '15px' }}
      />
    </Box>
  );
};

export default DualValueChip;
