import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Tabs, Tab, Typography, Box, Grid, IconButton, Paper, Slide, TextField, styled, useTheme } from "@mui/material";
import dayjs from "dayjs";
import Daily from "../../Components/Daily/Daily";
import Monthly from "../../Components/Monthly/Monthly";
import Yearly from "../../Components/Yearly/Yearly";
import { useAppContext } from "../../Context/Context";
import axios from "../../Api/Axios";
import useLongPress from "../../Components/Longpress";
import CreateProfileModal from "../../Components/CreateProfileModal";
import ManageProfileModal from "../../Components/ManageProfileModal";
import { toast } from "react-toastify";
import MonthlyUpperModal from "../../Components/Monthly/MonthlyUpperModal";
import ModalForImagePreview from "../../Components/Daily/ModalForImagePreview";
import DailyTopComponent from "../../Components/Daily/DailyTopComponent";
import CreateCompanyModal from "../../Components/CreateCompanyModal";
import ManageCompanyModal from "../../Components/ManageCompanyModal";
import ResponsiveDialog from "../../Components/DeleteDialog";
import CommonDrawer from "../../Components/CommonDrawer";
import AddUpdateTransactionModel from "../../Components/Daily/DailyModal";
import { capitalize } from "../../Utils/handler";
import SearchContent from "../../Components/SearchContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from 'tss-react/mui';
import AssignedDebitEntryTable from "../../Components/AssignedEntry/assignedDebitEntryTable";
import YearlyPdfGenerator from "../../Components/YearlyPdfGenerator";
import SearchPdfGenerator from "../../Components/SearchPdfGenerator";
import MergingNaming from "../../Components/MergingNaming";
import jsPDF from "jspdf";
import MonthlyPdfGenerator from "../../Components/MonthlyPdfGenerator"
import ChangePasswordModal from "../../Components/ChangePasswordModal";
import { useState } from "react";
import 'jspdf-autotable';
import { divideByHundred } from "../../Utils/enum";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </Typography>
  );
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    color: "white",
    backgroundColor: "transparent",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiInputBase-input": {
    paddingTop: 8,
  },
  "& .MuiInputBase-input::placeholder": {
    color: "black",
  },
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after": {
    borderBottom: "none",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
  return {
    gridItem: {
      "& .MuiGrid-item": {
        padding: "0px !important",
      },
    },
    gridContainer: {
      "& .MuiGrid-container": {
        margin: "0px !important",
      },
    },
    gridRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    scrollebleItem: {
      maxHeight: `calc(0.5 * ${window?.innerHeight}px)`,
      minHeight: `calc(0.77 * ${window?.innerHeight}px)`,
      overflowY: "hidden",
      width: "100%",
    },
  };
});

const tags = [
  { label: 'All', type: 0 },
  { label: 'Income (Credit)', type: 2 },
  { label: 'Expense (Debit)', type: 1 },
];

const Home = ({ }) => {
  const theme = useTheme();
  const { classes, cx } = useStyles();

  const { OnUpdateError, toggleLoader, user, isProfileMenuOpen, isCompanyMenuOpen, setIsProfileMenuOpen, setIsCompanyMenuOpen, getAllCompanies, companyList, selectedCompany, isMergeNameMenuOpen, setIsMergeNameMenuOpen, isSearch, setIsSearch, selectedButton, setSelectedButton, selectedTab, setSelectedTab, searchText, setSearchText, isOpenSearchDate, setIsOpenSearchDate, isOpenFrom, setIsOpenFrom, isOpenTo, setIsOpenTo, isOpenMonth, setIsOpenMonth, isOpenYear, setIsOpenYear, _globalSearch, searchData, startDate, setStartDate, endDate, setEndDate, filterDate, setFilterDate, date, setDate, openMonthlyPdf, setOpenMonthlyPdf, openYearlyPdf, setOpenYearlyPdf, value, setValue } = useAppContext();
  const searchTimeout = React.useRef(null);

  const [isAddUpdateTransactionModal, setIsAddUpdateTransactionModal] = React.useState(false);
  const [modalOpenTop, setModalOpenTop] = React.useState(false);
  const [data, setData] = React.useState({});
  const [openCreateProfileModal, setOpenCreateProfileModa] = React.useState(false);
  const [openCreateCompanyModal, setOpenCreateCompanyModal] = React.useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false)
  const [transactionType, setTransactionType] = React.useState(0);
  const [searchDate, setSearchDate] = React.useState(dayjs());
  const [loading, setLoading] = React.useState(false)
  const [profileData, setProfileData] = React.useState({
    fullName: "",
    email: "",
    // phoneNumber: "",
    password: "",
    confirmPassword: "",
    // dateOfBirth: "",
    // gender: "",
    permissions: [],
  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [baseImage, setBaseImage] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [transactionTypesForPermission, setTransactionTypesForPermission] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isCheckClick, setIsCheckClick] = React.useState({
    add: false,
    edit: false,
    copy: false,
    against: false
  })
  const [isProfileEdit, setIsProfileEdit] = React.useState(false)
  const [isCompanyEdit, setIsCompanyEdit] = React.useState(false)
  const [error, setError] = React.useState({})
  const [isCompanyDelete, setIsCompanyDelete] = React.useState(false);
  const [titleSearchedData, setTitleSearchData] = React.useState([])
  const [monthlyModalOpen, setMonthlyModalOpen] = React.useState(false)
  const [openModalForImage, setOpenModalForImage] = React.useState(false);
  const [clickedImageItemId, setClickedImageItemId] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([])
  const [isProfileDelete, setIsProfileDelete] = React.useState(false)
  const [isEntryDelete, setIsEntryDelete] = React.useState(false)
  const [isAgainstEntryDelete, setIsAgainstEntryDelete] = React.useState(false)
  const [isAgainstEntryData, setIsAgainstEntryData] = React.useState({})
  const [transactionByDate, setTransactionByDate] = React.useState([]);
  const [transactionByMonth, setTransactionByMonth] = React.useState([]);
  const [transactionByYear, setTransactionByYear] = React.useState([]);
  const [manageProfileData, setManageProfileData] = React.useState([]);
  // Global And Merge
  const [pdfUri, setPdfUri] = React.useState('');
  const [yearPdfUri, setYearPdfUri] = React.useState('');
  const [openPdf, setOpenPdf] = React.useState(false);

  const [isMonthlyPdfClick, setIsMonthlyPdfClick] = React.useState(false);

  const [fromNameValue, setFromNameValue] = React.useState(null);
  const [toNameValue, setToNameValue] = React.useState(null);
  const [fromNameOptions, setFromNameOptions] = React.useState([]);
  const [toNameOptions, setToNameOptions] = React.useState([]);
  const [defaultFromName, setDefaultFromName] = React.useState({});
  // const [searchData, setSearchData] = React.useState({});

  const selectedItemsIds = selectedItems?.map((e) => e?._id)

  //Against Entry States 
  const [isOpenAgainstEntry, setIsOpenAgainstEntry] = React.useState(false);
  const [againstTransactions, setAgainstTransactions] = React.useState([])
  const [selectedAgainstAssignedIds, setSelectedAgainstAssignedIds] = React.useState([])
  const [timer, setTimer] = React.useState(null);

  const [selectedDates, setSelectedDates] = React.useState([]);


  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');

  const [bothDatesSelected, setBothDatesSelected] = React.useState(false);
  const handleValueChange = (event, newValue) => {
    setValue(newValue);
    setModalOpenTop(false)
    setSelectedDates([])
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    setModalOpenTop(false)
    setSelectedDates([])
  };

  const handelNextDate = () => {
    setModalOpenTop(false)
    setSelectedItems([])
    let newDate;
    if (value === 0) {
      newDate = { ...date, days: date?.days?.add(1, "day") };
    } else if (value === 1) {
      newDate = { ...date, month: date?.month?.add(1, "month") };
    } else {
      newDate = { ...date, year: date?.year?.add(1, "year") };
    }
    setDate(newDate);
  };


  const handleClearAddUpdate = () => {
    // setIsAddUpdateTransactionModal(false)
    setData({});
    setError({})
    // setTransactionType(0)
    setIsEdit(false)
    setIsOpen(false)
    setModalOpenTop(false)
    setIsMergeNameMenuOpen(false)
    setSelectedItems([])
    _transactionByDate()
    setIsLongPress(false);
    setPressTimer(null);
  }

  const handelPrevDate = () => {
    setModalOpenTop(false)
    setSelectedItems([])
    let newDate;
    if (value === 0) {
      newDate = { ...date, days: date?.days?.subtract(1, "day") };
    } else if (value === 1) {
      newDate = { ...date, month: date?.month?.subtract(1, "month") };
    } else {
      newDate = { ...date, year: date?.year?.subtract(1, "year") };
    }
    setDate(newDate);
  };

  const toggleDrawerTop = () => {
    setModalOpenTop(!modalOpenTop);
    // setIsTopMenuOpen(true);
  };

  // Code for monthly modal
  const monthlyToggleDrawerTop = () => {
    setMonthlyModalOpen(!monthlyModalOpen);
    setSelectedDates([])
  };

  // Code for Longpress Event
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(toggleDrawerTop, defaultOptions);

  // let pressTimer = null;
  // let isLongPress = false
  const [isLongPress, setIsLongPress] = React.useState(false);
  const [pressTimer, setPressTimer] = React.useState(null);

  const handleClearTopModel = () => {
    setSelectedItems([])
    setAgainstTransactions([])
    setData({});
    setError({})
    setSelectedAgainstAssignedIds([])
    setModalOpenTop(false)
    setOpenCreateCompanyModal(false);
    setIsCompanyEdit(false);
    setIsOpenAgainstEntry(false)
    setIsMergeNameMenuOpen(false)
    setIsOpenAgainstEntry(false);
    // _transactionByDate();
    setIsLongPress(false);
    setPressTimer(null);
  }

  const handleClose = () => {
    setSelectedItems([])
    setAgainstTransactions([])
    setData({});
    setError({})
    setSelectedAgainstAssignedIds([])
    setModalOpenTop(false)
    setOpenCreateCompanyModal(false);
    setIsCompanyEdit(false);
    setIsOpenAgainstEntry(false)
    setIsMergeNameMenuOpen(false)
    setIsOpenAgainstEntry(false);
    setIsEntryDelete(false)
    setIsLongPress(false);
    setPressTimer(null);
    _transactionByDate();

  }

  const handlePressStart = (item, event, type) => {
    if (pressTimer) {
      clearTimeout(pressTimer)
    };
    // event.preventDefault();
    const timer = setTimeout(() => {
      if (!isLongPress) {
        if (type === "daily") {
          if (!modalOpenTop) {
            setModalOpenTop(true);
          }
        }
        setIsLongPress(true);
        const updatedSelectedItems = [...selectedItems];
        const index = updatedSelectedItems.findIndex(selectedItem => selectedItem._id === item._id);
        if (index === -1) {
          updatedSelectedItems.push(item);
        } else {
          updatedSelectedItems.splice(index, 1);
        }
        setSelectedItems(updatedSelectedItems);
      }
    }, 500);
    setPressTimer(timer);
  };

  const handleTouchEnd = () => {
    if (pressTimer) {
      clearTimeout(pressTimer)
    };
  }

  useEffect(() => {
    return () => {
      if (pressTimer) {
        clearTimeout(pressTimer)
      };
    }
  }, [pressTimer]);

  const onClick = (item) => {
    if (!modalOpenTop) {
      setModalOpenTop(true);
    }
    const updatedSelectedItems = [...selectedItems];
    const index = updatedSelectedItems.findIndex(selectedItem => selectedItem._id === item._id);
    if (index === -1) {
      updatedSelectedItems.push(item);
    } else {
      updatedSelectedItems.splice(index, 1);
    }
    setSelectedItems(updatedSelectedItems);
  }


  const handleSelectMontlyDate = (date) => {
    const dateString = dayjs(new Date(date)).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    const index = selectedDates.indexOf(dateString);
    if (index === -1) {
      setSelectedDates([...selectedDates, dateString]);
    } else {
      const updatedDates = [...selectedDates];
      updatedDates.splice(index, 1);
      setSelectedDates(updatedDates);
    }
  };


  const handlePressEnd = () => {
    if (pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);
    }
    setIsLongPress(false);
  };

  const handleClick = (item, event) => {
    if (isLongPress) {
      setIsLongPress(false);
      return;
    }
    const updatedSelectedItems = [...selectedItems];
    const index = updatedSelectedItems.findIndex(selectedItem => selectedItem._id === item._id);
    if (index === -1) {
      updatedSelectedItems.push(item);
    } else {
      updatedSelectedItems.splice(index, 1);
    }
    setSelectedItems(updatedSelectedItems);
  };

  const handleMouseDown = (ele, e) => {
    handlePressStart(ele, e, "daily");
  };

  const handleMouseUpOrLeave = () => {
    handlePressEnd();
  };

  const handleTouchStart = (ele, e) => {
    handlePressStart(ele, e, "daily");
  };


  //Code for handle manageProfile modal
  const handleCloseProfile = () => {
    setIsProfileMenuOpen(false);
  };
  const handleCloseCompany = () => {
    setIsCompanyMenuOpen(false);
    // setIsCompanyEdit(false);
    _transactionByDate()
  };

  //Code for handle createProfile modal
  const handlecreateProfileCloseModal = () => {
    setOpenCreateProfileModa(!openCreateProfileModal);
    setProfileData({
      fullName: "",
      email: "",
      // phoneNumber: "",
      password: "",
      confirmPassword: "",
      // dateOfBirth: "",
      // gender: "",
      permissions: [],
    });
  };

  const handleChangePasswordCloseModal = () => {
    setIsChangePasswordOpen(!isChangePasswordOpen)
  }

  const handleChangeForCreateProfile = (e) => {
    const { name, value, checked } = e.target;
    const permissionValue = parseInt(value, 10);
    setProfileData((prevState) => {
      if (e.target.type === "checkbox") {
        return {
          ...prevState,
          permissions: checked ?
            [...(prevState.permissions || []), permissionValue] :
            prevState.permissions.filter((val) => val !== permissionValue),
        };
      } else {
        const updatedValue = name === "fullName" ? capitalize(value) : value;
        return {
          ...prevState,
          [name]: updatedValue,
        };
      }
    });
  };

  //Code for manage company modal
  const handlecreateCompanyCloseModal = () => {
    setOpenCreateCompanyModal(!openCreateCompanyModal);
  };

  // Code for bottom drawer
  const toggleDrawerBottom = () => {
    setIsAddUpdateTransactionModal(!isAddUpdateTransactionModal);
    setData({});
    setError({})
    // setTransactionType(0)
    setIsEdit(false)
    setIsCheckClick({
      add: false,
      edit: false,
      copy: false
    })
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let capitalizedValue = value;

    if (name === 'amount' || name === 'openingBalance') {
      const numericValue = value.replace(/\D/g, '');
      capitalizedValue = numericValue ? divideByHundred(numericValue) : "";
      setData((prevState) => ({
        ...prevState,
        [name]: numericValue,
        formattedAmount: capitalizedValue, // Keep the formatted value in sync
      }));
    } else if (name === 'name' || name === 'description') {
      setData((prevState) => ({
        ...prevState,
        [name]: capitalizedValue,
      }));
    }
    else {
      setData((prevState) => ({
        ...prevState,
        [name]: capitalizedValue,
      }));
    }

    if (name === 'amount') {
      if (capitalizedValue.length > 8) {
        setError((prevError) => ({
          ...prevError,
          amountLength: 'Amount is too long.',
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          amountLength: '',
        }));
      }
    }

    if (name === "voucherNo") {
      if (capitalizedValue?.length > 7) {
        setError((prevError) => ({
          ...prevError,
          voucherNoLength: 'Voucher number is too long.',
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          voucherNoLength: '',
        }));
      }
    }
  };


  const handleInputChange = (name, newInputValue) => {

    setData(prevData => ({
      ...prevData,
      [name]: newInputValue ? newInputValue : ""
    }));

    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        _getTitleSearchData(newInputValue, true);
      }, 500)
    );

  };

  const handleUpload = async (file, type) => {
    const formData = new FormData();
    formData.append("image", file[0]);
    toggleLoader();
    setBaseImage(file)
    if (file[0]?.size <= 10000000) {
      axios
        .post("/upload/image/attachment", formData)
        .then((res) => {
          if (res?.data?.data) {
            if (type === "company") {
              setData({ ...data, companyLogo: res?.data?.data?.image })
            } else {
              setData({ ...data, image: res?.data?.data?.image })
              // const reader = new FileReader()

              // reader.readAsDataURL(file)
            }
          }
        }).catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => {
          toggleLoader();
        });
    } else {
      toast.error("Upload file allowed size is 10MB");
      toggleLoader();
      // setIsAddUpdateTransactionModal(false);
    }
  };

  const handleDeleteFile = (type) => {
    let body = {
      url: type == "company" ? data?.companyLogo : data?.image,
    };
    axios
      .post(`/upload/delete_file`, body)
      .then((res) => {
        setData((prevState) => ({
          ...prevState,
          image: null,
          companyLogo: null
        }));
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };


  const _getTitleSearchData = async (search, name, isName) => {
    let body = { companyId: selectedCompany?._id, isMerge: false, search };
    // toggleLoader();
    await axios
      .post(`admin/getTitleSearch`, body)
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.data) {
            const response = res?.data?.data?.response;
            if (isName) {
              if (name === "From Name") {
                setTitleSearchData(response);
                setFromNameOptions(response);
                setToNameOptions([]);
              } else if (name === "To Name") {
                setTitleSearchData(response);
                setFromNameOptions([]);
                setToNameOptions(response);
              }
            } else {
              setTitleSearchData(response)
            }
          }
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
        toast.error(err?.data?.message);
      })
      .finally(() => {
        // toggleLoader();
      });
  };

  //Against Handlers

  const handleSelectAgainstEntry = (event, item) => {
    if (event.target.checked) {
      setSelectedAgainstAssignedIds((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectedAgainstAssignedIds((prevSelected) => prevSelected.filter((selectedItem) => selectedItem._id !== item._id));
    }
  };

  const validateAgainstEntry = () => {
    let newErrors = {};
    let isValid = true;

    if (selectedAgainstAssignedIds?.length === 0) {
      isValid = false;
      newErrors["isSelectedDebitAmount"] = "Please assign atleast one item.";
    }

    const sumOfAssignedAmounts = selectedAgainstAssignedIds.reduce((total, item) => total + item?.amount, 0);
    if (selectedAgainstAssignedIds.length > 0 && againstTransactions?.remainingAmount < sumOfAssignedAmounts) {
      isValid = false;
      newErrors["isBalanceExceeded"] = "Your balance exceeds the credit amount.";
    }

    setError(newErrors);
    return isValid;
  };

  const _addTransactionEntry = async () => {
    if (validateAgainstEntry()) {
      const data = selectedAgainstAssignedIds?.map((e) => e?._id).join(",")
      let body = {
        "creditId": selectedItems?.map((e) => e?._id),
        "assignedTo": selectedAgainstAssignedIds?.map(item => item._id)
      }
      toggleLoader();
      await axios.post(`admin/transactionEntry`, body)
        .then((res) => {
          if (res?.data?.status === 200) {
            if (res?.data?.data) {
              handleClose()
            }
          }
        })
        .catch((err) => {
          OnUpdateError(err?.data?.message);
          toast.error(err?.data?.message);
        })
        .finally(() => {
          toggleLoader();
        });
    }
  };

  const _deleteAssignedEntry = async () => {
    toggleLoader();
    await axios.post(`admin/deleteTransactionEntry/${isAgainstEntryData?._id}`)
      .then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          _getRemainingDebitTransactions();
          setIsAgainstEntryDelete(false);
          setIsAgainstEntryData({});
          setIsLongPress(false);
          setPressTimer(null);
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
        toast.error(err?.data?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  }

  const _getRemainingDebitTransactions = async () => {
    let body = {
      selectedId: selectedItems?.map((e) => e?._id),
      company_id: selectedCompany?._id
    }
    toggleLoader();
    await axios.post(`admin/remainingDebitTransactions`, body)
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.data) {
            const data = res?.data?.data?.remainingDebitTransaction
            setAgainstTransactions(data)
          }
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
        toast.error(err?.data?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  const handleTransactionType = (val) => {
    setTransactionType(val);
    setError({})
  };


  const handleChangeName = (name, value) => {
    if (name === "From Name") {
      setFromNameValue(value);
      // _getTitleSearchData(value, "From Name", true);
    } else if (name === "To Name") {
      setToNameValue(value);
      _getTitleSearchData(value, "To Name", true);
    }
  };

  const validateMergingName = () => {
    let newErrors = {};
    let isValid = true;

    if (defaultFromName?.title === toNameValue) {
      isValid = false;
      newErrors["Names"] = "Names are same!";
    }
    if (!toNameValue || toNameValue === null) {
      isValid = false;
      newErrors["toName"] = "Select to name!"
    }

    setError(newErrors);
    return isValid;
  };

  const handleAddMergeName = async () => {
    if (validateMergingName()) {
      let body = {
        id: defaultFromName?._id,
        changeName: toNameValue || ""
      };
      toggleLoader();
      await axios
        .post(`admin/transaction/merge`, body)
        .then((res) => {
          if (res?.data?.status === 200) {
            toast.success(res?.data?.message || "Transaction has been successfully updated!");
            handleCloseNameDrawer();
            _transactionByDate();
            setIsLongPress(false);
            setPressTimer(null);
          }
        })
        .catch((err) => {
          OnUpdateError(err?.data?.message);
          toast.error(err?.data?.message);
        })
        .finally(() => {
          toggleLoader();
        });
    }
  }

  const handleModalForImage = () => {
    setOpenModalForImage(!openModalForImage)
    setClickedImageItemId(null);
    setIsLongPress(false);
    setPressTimer(null);
  }

  const handleDailyModalForImage = (data) => {
    setData(data);
    setClickedImageItemId(data?._id)
    setOpenModalForImage(!openModalForImage)
    setIsLongPress(false);
    setPressTimer(null);
  }

  const validateAddTransaction = () => {
    let newErrors = {};
    let isValid = true;

    if (!data?.title) {
      isValid = false;
      newErrors["title"] = `Please enter Title.`
    }
    // if (data?.title && data?.title?.length > 20) {
    //   isValid = false;
    //   newErrors["titleLength"] = `Title is too long.`
    // }
    console.log(typeof data?.amount, "harshssss");
    if (data?.amount == "00" || !data?.amount) {
      isValid = false;
      newErrors["amount"] = `Please enter Amount.`
    }
    if (data?.amount && data?.amount?.length > 10) {
      isValid = false;
      newErrors["amountLength"] = `Amount is too long.`
    }
    setError(newErrors);
    return isValid;
  };

  const _transactionByDate = async () => {
    setLoading(true)
    const body = {
      // limit: 10,
      page: 1,
      search: "",
      date: date?.days,
    };
    if (selectedCompany?._id) {
      body.company_id = selectedCompany?._id
    }
    await axios.post("admin/transactionByDate", body)
      .then((res) => {
        if (res?.data?.data?.response) {
          setTransactionByDate(res?.data?.data?.response);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => {
        setLoading(false)
      });
  };

  const _transactionByMonth = async () => {
    const body = {
      // limit: 10,
      page: 1,
      search: "",
      date: date?.month,
      company_id: selectedCompany?._id
    };

    await axios.post("admin/transactionByMonth", body)
      .then((res) => {
        if (res?.data?.data) {
          setTransactionByMonth(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => { });
  };

  const _transactionByYear = async () => {
    const body = {
      // limit: 10,
      page: 1,
      search: "",
      date: date?.year,
      company_id: selectedCompany?._id
    };

    await axios.post("admin/transactionByYear", body)
      .then((res) => {
        if (res?.data?.data) {
          setTransactionByYear(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => {

      });
  };

  const _getAllUserProfiles = async () => {
    toggleLoader();
    await axios
      .get("admin/getAllUser")
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.data) {
            setManageProfileData(res?.data?.data);
            setProfileData(res?.data?.data?.filter((profile) => profile?._id !== user?._id));
          }
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  // const _globalSearch = async (search, type, fromDate, toDate, month, year, isAll) => {
  //   toggleLoader();
  //   const body = {
  //     // limit: 20,
  //     // page: 1,
  //     search: search || "",
  //     company_id: selectedCompany?._id || "",
  //   };
  //   if (type) {
  //     body.entryType = type === 2 ? 0 : type || 0
  //   }
  //   if (fromDate && toDate && selectedButton !== "allTime") {
  //     body.fromDate = fromDate
  //     body.toDate = toDate
  //   }
  //   if (month && selectedButton !== "allTime") {
  //     body.month = month
  //   }
  //   if (year && selectedButton !== "allTime") {
  //     body.year = year
  //   }
  //   await axios
  //     .post(`admin/globleSearch`, body)
  //     .then((res) => {
  //       if (res?.data?.data) {
  //         console.log(res?.data, "dataaaa")
  //         setSearchData(res?.data?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err?.message);
  //       OnUpdateError(err?.message);
  //     })
  //     .finally(() => {
  //       toggleLoader();
  //     });
  // };

  const addUpdateTransaction = async () => {
    if (validateAddTransaction()) {
      const body = {
        title: capitalize(data?.title),
        amount: data?.amount,
        transactionType: transactionType,
        voucherNo: data?.voucherNo || "",
        description: data?.description || "",
        date: dayjs(date?.days),
        image: data?.image || "",
        company_id: selectedCompany?._id
      };
      if (isEdit && data?._id) {
        body.id = data?._id;
      }
      toggleLoader();
      await axios
        .post(`admin/transaction/${isEdit ? "update" : "add"}`, body)
        .then((res) => {
          if (res?.data?.status === 200) {
            handleClearAddUpdate()
            if (isEdit && data?._id) {
              setTransactionType(0)
              setIsAddUpdateTransactionModal(false)
            }
          }
        })
        .catch((err) => {
          OnUpdateError(err?.data?.message);
        })
        .finally(() => {
          toggleLoader();
        });
    }
  }

  const deleteProfile = async (id) => {
    // toggleLoader();
    await axios
      .delete(`admin/delete/${id}`)
      .then((res) => {
        if (res?.data?.data) {
          setOpenCreateProfileModa(false)
          setIsProfileDelete(false)
          _getAllUserProfiles()
          handlecreateProfileCloseModal();
          setIsProfileEdit(false);
          setOpenCreateProfileModa(false);
          setIsProfileEdit(false);
          setSelectedCompanies([]);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        OnUpdateError(err?.message);
      }).finally(() => {
        // toggleLoader();
      })
  };

  const _deleteCompany = async (id) => {
    // toggleLoader();
    await axios
      .delete(`admin/company/delete/${id}`)
      .then((res) => {
        if (res?.data?.data) {
          setOpenCreateCompanyModal(false)
          getAllCompanies()
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        OnUpdateError(err?.message);
      }).finally(() => {
        // toggleLoader();
      })
  };

  const _transactionById = async (id) => {
    toggleLoader();
    await axios
      .get(`admin/transaction/${id}`)
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.data) {
            setData({ ...res?.data?.data })
            setTransactionType({ ...transactionType, id: res?.data?.data?.transactionType })
          }
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
        toast.error(err?.data?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  const handleCopy = async (date) => {
    toggleLoader();
    let body = { date: dayjs(date), transactionList: selectedItemsIds }
    await axios
      .post(`admin/transaction/copy`, body)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          setDate({ ...date, days: date });
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
        toast.error(err?.data?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  const handleDelete = async () => {
    toggleLoader();
    let body = { transactionList: selectedItemsIds }
    await axios
      .post(`admin/transaction/delete`, body)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          handleClose()
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
        toast.error(err?.data?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  const deleteTransactionMonthlyByDate = async () => {
    toggleLoader();
    let body = {
      companyId: selectedCompany?._id,
      dates: selectedDates
    }
    await axios
      .post(`admin/transactionMonthly/delete`, body)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          setMonthlyModalOpen(false)
          setSelectedDates([])
          _transactionByMonth()
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
        toast.error(err?.data?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  const validateCompany = () => {
    let newErrors = {};
    let isValid = true;

    if (!data?.name) {
      isValid = false;
      newErrors['name'] = `Name is required.`;
    }
    if (data?.openingBalance && data?.openingBalance?.length > 15) {
      isValid = false;
      newErrors["openingBalanceValidate"] = `Opening Balance is too long.`
    }
    setError(newErrors);
    return isValid;
  };

  const addUpdateCompany = async () => {
    if (validateCompany()) {
      const body = {
        name: data?.name || "",
        shortName: data?.shortName || "",
        companyLogo: data?.companyLogo || "",
        openingBalance: data?.openingBalance || "0"
      };
      if (isCompanyEdit) { body.id = data?._id };
      toggleLoader();
      await axios
        .post(`admin/company/${isCompanyEdit ? "update" : "create"}`, body)
        .then(async (res) => {
          if (res?.data?.data) {
            setOpenCreateCompanyModal(false)
            getAllCompanies()
          }
        })
        .catch((err) => {
          toast.error(err?.message);
          OnUpdateError(err?.message);
        }).finally(() => {
          toggleLoader();
        })
    }
  };

  const handleCloseNameDrawer = () => {
    setIsMergeNameMenuOpen(false);
    setFromNameValue(null);
    setToNameValue(null);
    setFromNameOptions([]);
    setToNameOptions([]);
    setIsLongPress(false);
    setPressTimer(null);
  }

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      // _globalSearch(value);
    }, 500);
    setSelectedButton("allTime");
    setSelectedTab(0);
  };

  const handleCloseSearch = () => {
    setIsSearch(false);
    setSelectedButton("allTime");
    setSelectedTab(0);
    setIsOpenSearchDate(false);
    setSearchText("");
    _globalSearch();
    setStartDate(dayjs().subtract(1, "month"));
    setEndDate(dayjs())
    setIsOpenMonth(false)
    setIsOpenYear(false)
    setIsOpenFrom(false)
    setIsOpenTo(false)
    setEndDate(null)
    setStartDate(null)
    setFilterDate({ ...filterDate, month: dayjs(), year: dayjs() })
    setBothDatesSelected(false)
  };

  const filterTransactions = (transactions, type) => {
    if (type === 0) return transactions;
    return transactions?.filter(transaction => transaction.transactionType === (type === 2 ? 0 : 1));
  };

  const allTransactions = searchData?.response || [];
  const creditTransactions = filterTransactions(allTransactions, 2);
  const debitTransactions = filterTransactions(allTransactions, 1);

  const handleButtonClick = (type) => {
    setSelectedButton(type);

    // _globalSearch(searchText || "", type === 'allTime' ? "" : type);
  };
  const handleClickPdf = (transactionsData) => {
    if (openMonthlyPdf) {
      MonthlyPdfGenerator({
        onPdfGenerated: (pdfUri) => { setPdfUri(pdfUri); },
        theme,
        searchData,
        isDownload: true,
        filterDate,
        startDate,
        endDate,
        selectedButton,
        selectedCompany: selectedCompany?.name
      })
    } else if (openYearlyPdf) {
      YearlyPdfGenerator({
        onPdfGenerated: (yearPdfUri) => { setYearPdfUri(yearPdfUri); },
        theme,
        searchData,
        transactionByYear,
        isDownload: true,
        filterDate,
        startDate,
        endDate,
        selectedButton,
        selectedCompany: selectedCompany?.name
      })
    } else {
      SearchPdfGenerator({
        theme,
        transactionsData,
        filterDate,
        startDate,
        endDate,
        selectedButton,
        searchData,
        selectedCompany: selectedCompany?.name
      });
      setOpenPdf(true);
    }
  }

  const downloadMonthlyPdf = async (totalBalance, searchDetails) => {
    setOpenMonthlyPdf(true);
    setIsMonthlyPdfClick(true);

    setSelectedButton("month")
    setFilterDate({
      ...filterDate, month: date?.month
    })
  }

  const handleCloseMonthlyPdf = () => {
    setOpenMonthlyPdf(false);
    setPdfUri('')
    setIsOpenMonth(false)
    setIsOpenYear(false)
    setIsOpenFrom(false)
    setIsOpenTo(false)
    setSelectedTab(0)
    setEndDate(null)
    setStartDate(null)
    setBothDatesSelected(false);
    setSelectedButton("allTime");
  };

  const saveMonthlyPdf = (monthlyData, cfAmount, totalBalance) => {
    if (!monthlyData) return;

    const doc = new jsPDF();
    doc.text(`Monthly Report for ${month}/${year}`, 10, 10);
    doc.autoTable({ html: '#monthly-table' });
    doc.save('monthly-report.pdf');
  };

  const downloadYearlyPdf = (yearlyData, searchDetails) => {
    setOpenYearlyPdf(true);
    setSelectedButton("year");
    setFilterDate({
      ...filterDate, year: date?.year
    });
  }

  const handleCloseYearlyPdf = () => {
    setOpenYearlyPdf(false);
    setYearPdfUri('')
    setBothDatesSelected(false)
    setIsOpenMonth(false)
    setIsOpenYear(false)
    setIsOpenFrom(false)
    setIsOpenTo(false)
    setSelectedTab(0)
    setEndDate(null)
    setStartDate(null)
    setSelectedButton("allTime");
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  const groupedData = transactionByMonth?.response?.reduce((acc, entry) => {
    const date = dayjs(entry?.date).format("DD MMMM YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(entry);
    return acc;
  }, {});


  useEffect(() => {
    console.log(date, value, selectedCompany, "selectedCompany");
    if (value === 0 && date?.days && !isCheckClick.copy && selectedCompany?._id) {
      _transactionByDate();
    } else if (value === 1 && date?.month && selectedCompany?._id) {
      _transactionByMonth();
    } else if (value === 2 && date?.year && selectedCompany?._id) {
      _transactionByYear();
    } else {
      return;
    }
  }, [date, value, selectedCompany]);

  useEffect(() => {
    setProfileData({
      ...profileData,
      permissions: transactionTypesForPermission,
    });
  }, [transactionTypesForPermission]);


  useEffect(() => {
    if (selectedItems?.length === 0 && modalOpenTop) {
      handleClearTopModel()
    }
  }, [selectedItems]);

  useEffect(() => {
    if (selectedItems?.length > 0 && isOpenAgainstEntry) {
      _getRemainingDebitTransactions()
    }
  }, [selectedItems, isOpenAgainstEntry]);

  useEffect(() => {
    if (isProfileMenuOpen) {
      _getAllUserProfiles()
    }
  }, [isProfileMenuOpen]);

  useEffect(() => {
    if (isSearch || openMonthlyPdf || openYearlyPdf) {
      if ((searchText || openMonthlyPdf || openYearlyPdf)) {
        _globalSearch()
      }
    }
  }, [isSearch, searchText, endDate, filterDate?.month, filterDate?.year, openMonthlyPdf, openYearlyPdf, selectedButton, startDate, selectedTab])


  useEffect(() => {
    if (selectedDates?.length > 0) {
      setMonthlyModalOpen(true)
    } else {
      setMonthlyModalOpen(false)
    }
  }, [selectedDates])

  useEffect(() => {
    if (searchData?.response) {
      if (openMonthlyPdf) {

        MonthlyPdfGenerator({
          onPdfGenerated: (pdfUri) => { setPdfUri(pdfUri); },
          theme,
          searchData,
          isDownload: false,
          filterDate,
          startDate,
          endDate,
          selectedButton,
          selectedCompany: selectedCompany?.name
        })
      } else if (openYearlyPdf) {
        YearlyPdfGenerator({
          onPdfGenerated: (yearPdfUri) => { setYearPdfUri(yearPdfUri); },
          theme,
          searchData,
          transactionByYear,
          isDownload: false,
          filterDate,
          startDate,
          endDate,
          selectedButton,
          selectedCompany: selectedCompany?.name
        })
      }
    }
  }, [searchData, openYearlyPdf, openMonthlyPdf])

  return (
    <>
      <Grid container spacing={1} xs={12} component={Paper} elevation={0}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBar
              position="static"
              sx={{ bgcolor: theme.palette.info.main, boxShadow: "0" }}
            >
              <Tabs
                value={value}
                onChange={handleValueChange}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "white",
                  },
                }}
                indicatorColor="primary"
                textColor={theme.palette.bgInfo.main}
                variant="fullWidth"
                aria-label="action tabs example"
              >
                <Tab label="DAILY" {...a11yProps(0)} sx={{ color: theme.palette.bgInfo.main }} />
                <Tab label="MONTHLY" {...a11yProps(1)} sx={{ color: theme.palette.bgInfo.main }} />
                <Tab label="YEARLY" {...a11yProps(2)} sx={{ color: theme.palette.bgInfo.main }} />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {companyList?.length === 0 && transactionByDate?.response?.length === 0 ?
                  <Box textAlign={"center"} mt={5}>
                    <Typography >
                      Please create company first.
                    </Typography>
                  </Box>
                  :
                  <Daily
                    onClick={onClick}
                    handleTouchEnd={handleTouchEnd}
                    date={date}
                    setDate={setDate}
                    handelNextDate={handelNextDate}
                    handelPrevDate={handelPrevDate}
                    toggleDrawerBottom={toggleDrawerBottom}
                    setIsLongPress={setIsLongPress}
                    setPressTimer={setPressTimer}
                    handlePressStart={handlePressStart}
                    handlePressEnd={handlePressEnd}
                    transactionByDate={transactionByDate}
                    modalOpenTop={modalOpenTop}
                    setModalOpenTop={setModalOpenTop}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    data={data}
                    setIsCheckClick={setIsCheckClick}
                    isCheckClick={isCheckClick}
                    selectedItems={selectedItems}
                    handleClick={handleClick}
                    handleCopy={handleCopy}
                    handleDailyModalForImage={handleDailyModalForImage}
                    clickedImageItemId={clickedImageItemId}
                    loading={loading}
                    setSelectedItems={setSelectedItems}
                  />
                }
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {companyList?.length > 0 ?
                  <Monthly
                    date={date}
                    setDate={setDate}
                    handelNextDate={handelNextDate}
                    handelPrevDate={handelPrevDate}
                    transactionByMonth={transactionByMonth}
                    downloadMonthlyPdf={downloadMonthlyPdf}
                    selectedDates={selectedDates}
                    handleSelectMontlyDate={handleSelectMontlyDate}
                    searchData={searchData}
                    loading={loading}
                  />
                  : <Box textAlign={"center"} mt={5}>
                    <Typography >
                      Please create company first.
                    </Typography>
                  </Box>}
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                {companyList?.length > 0 ?
                  <Yearly
                    date={date}
                    setDate={setDate}
                    handelNextDate={handelNextDate}
                    handelPrevDate={handelPrevDate}
                    transactionByYear={transactionByYear}
                    downloadYearlyPdf={downloadYearlyPdf}
                    searchData={searchData}
                  />
                  : <Box textAlign={"center"} mt={5}>
                    <Typography >
                      Please create company first.
                    </Typography>
                  </Box>}
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>

        {isAddUpdateTransactionModal && (
          <AddUpdateTransactionModel
            data={data}
            setData={setData}
            handleChange={handleChange}
            toggleDrawerBottom={toggleDrawerBottom}
            isAddUpdateTransactionModal={isAddUpdateTransactionModal}
            handleTransactionType={handleTransactionType}
            transactionType={transactionType}
            date={date}
            error={error}
            handleUpload={handleUpload}
            handleDeleteFile={handleDeleteFile}
            _getTitleSearchData={_getTitleSearchData}
            titleSearchedData={titleSearchedData}
            handleModalForImage={handleModalForImage}
            onSubmit={addUpdateTransaction}
            user={user}
            handleClearAddUpdate={handleClearAddUpdate}
            setIsAddUpdateTransactionModal={setIsAddUpdateTransactionModal}
            setTitleSearchData={setTitleSearchData}
            handleInputChange={handleInputChange}
            setTransactionType={setTransactionType}
            isEdit={isEdit}
          />
        )}
        {isCompanyMenuOpen && (
          <CommonDrawer
            title={"Manage Company"}
            isDelete={isCompanyEdit}
            onClose={handleCloseCompany}
            open={isCompanyMenuOpen}
            content={<ManageCompanyModal
              handlecreateCompanyCloseModal={handlecreateCompanyCloseModal}
              setIsCompanyEdit={setIsCompanyEdit}
              manageCompanyData={companyList}
              setData={setData}
            />}
          />
        )}
        {isProfileMenuOpen && (
          <CommonDrawer
            title={"Manage Profile"}
            onClose={handleCloseProfile}
            open={isProfileMenuOpen}
            content={<ManageProfileModal
              handlecreateProfileCloseModal={handlecreateProfileCloseModal}
              manageProfileData={manageProfileData}
              setIsProfileEdit={setIsProfileEdit}
              setProfileData={setProfileData}
              setSelectedCompanies={setSelectedCompanies}
              handleChangePasswordCloseModal={handleChangePasswordCloseModal}
            />}
          />
        )}
        {openCreateCompanyModal && (
          <CommonDrawer
            open={openCreateCompanyModal}
            onClose={handleClose}
            onDelete={() => setIsCompanyDelete(true)}
            isDelete={isCompanyEdit}
            title={`${isCompanyEdit ? "Edit" : "Add"} Company`}
            content={<CreateCompanyModal
              data={data}
              setData={setData}
              handleChange={handleChange}
              _getAllCompanies={getAllCompanies}
              isCompanyEdit={isCompanyEdit}
              handleDeleteFile={handleDeleteFile}
              handleUpload={handleUpload}
              setOpenCreateCompanyModal={setOpenCreateCompanyModal}
              onSubmit={addUpdateCompany}
              error={error}
            />}
          />
        )}
        {isOpenAgainstEntry && (
          <CommonDrawer
            open={isOpenAgainstEntry}
            onClose={handleClose}
            isDelete={false}
            title={"Against Entry"}
            content={<>
              <AssignedDebitEntryTable
                againstTransactions={againstTransactions}
                selectedAgainstAssignedIds={selectedAgainstAssignedIds}
                handleSelectAgainstEntry={handleSelectAgainstEntry}
                _addTransactionEntry={_addTransactionEntry}
                error={error}
                selectedItems={selectedItems}
                // _deleteAssignedEntry={_deleteAssignedEntry}
                setIsAgainstEntryDelete={setIsAgainstEntryDelete}
                setIsAgainstEntryData={setIsAgainstEntryData}

              />
            </>}
          />
        )}

        {openCreateProfileModal && (
          <CommonDrawer
            open={openCreateProfileModal}
            onClose={() => {
              handlecreateProfileCloseModal();
              setIsProfileEdit(false);
              setProfileData({
                fullName: "",
                email: "",
                // phoneNumber: "",
                password: "",
                confirmPassword: "",
                // dateOfBirth: "",
                // gender: "",
                permissions: [],
              });
              setOpenCreateProfileModa(false);
              setIsProfileEdit(false);
              setSelectedCompanies([]);
            }}
            onDelete={() => setIsProfileDelete(true)}
            title={`${isProfileEdit ? "Edit" : "Add"} Profile`}
            isDelete={isProfileEdit && profileData?.userType !== 0}
            content={<CreateProfileModal
              setOpenCreateProfileModa={setOpenCreateProfileModa}
              profileData={profileData}
              setProfileData={setProfileData}
              handleChangeForCreateProfile={handleChangeForCreateProfile}
              _getAllUserProfiles={_getAllUserProfiles}
              isProfileEdit={isProfileEdit}
              setIsProfileEdit={setIsProfileEdit}
              companyList={companyList}
              setSelectedCompanies={setSelectedCompanies}
              selectedCompanies={selectedCompanies}
            />}
          />
        )}

        {isChangePasswordOpen && (
          <CommonDrawer
            open={isChangePasswordOpen}
            onClose={() => {
              handleChangePasswordCloseModal()
            }}
            title={"Change Password"}
            content={<ChangePasswordModal
              setIsChangePasswordOpen={setIsChangePasswordOpen}
              profileData={profileData}
              _getAllUserProfiles={_getAllUserProfiles}

            />}
          />
        )
        }
        {isCompanyDelete && (
          <ResponsiveDialog
            open={isCompanyDelete}
            onSave={() => { _deleteCompany(data?._id); setIsCompanyDelete(false); setData({}); setIsCompanyEdit(false); setError({}) }}
            onClose={() => { setIsCompanyDelete(false); setIsCompanyDelete(false); setError({}) }}
            title="Delete Company"
            content={<>
              <Grid>
                <Typography>Are you sure you want to delete company?</Typography>
              </Grid>
            </>}
          />
        )}
        {isProfileDelete && (
          <ResponsiveDialog
            open={isProfileDelete}
            onSave={() => { deleteProfile(profileData?._id); }}
            onClose={() => setIsProfileDelete(false)}
            title="Delete Profile"
            content={<>
              <Grid>
                <Typography>Are you sure you want to delete Profile?</Typography>
              </Grid>
            </>}
          />
        )}

        {isEntryDelete && (
          <ResponsiveDialog
            open={isEntryDelete}
            onSave={handleDelete}
            onClose={() => {
              setIsEntryDelete(false);
              setIsLongPress(false);
              setPressTimer(null);
            }}
            title="Delete Entry"
            content={<>
              <Grid>
                <Typography>Are you sure you want to delete entry's?</Typography>
              </Grid>
            </>}
          />
        )}

        {isAgainstEntryDelete && (
          <ResponsiveDialog
            open={isAgainstEntryDelete}
            onSave={_deleteAssignedEntry}
            onClose={() => {
              setIsAgainstEntryDelete(false);
              setIsAgainstEntryData({});
              setIsLongPress(false);
              setPressTimer(null);
            }}
            title="Delete Assigned Entry"
            content={<>
              <Grid>
                <Typography>Are you sure you want to delete entry's?</Typography>
              </Grid>
            </>}
          />
        )}

        {monthlyModalOpen && (
          <MonthlyUpperModal
            monthlyModalOpen={monthlyModalOpen}
            setMonthlyModalOpen={setMonthlyModalOpen}
            monthlyToggleDrawerTop={monthlyToggleDrawerTop}
            deleteTransactionMonthlyByDate={deleteTransactionMonthlyByDate}
            setSelectedDates={setSelectedDates}
          />
        )}
        {openModalForImage && (
          <ModalForImagePreview
            openModalForImage={openModalForImage}
            handleModalForImage={handleModalForImage}
            data={data}
            handleClose={() => { setData({}); setIsEdit(false); setModalOpenTop(false); setIsLongPress(false); setPressTimer(null); }}
            baseImage={baseImage}
          />
        )}
        {modalOpenTop && <DailyTopComponent
          modalOpenTop={modalOpenTop}
          handleClearTopModel={handleClearTopModel}
          toggleDrawerTop={toggleDrawerTop}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          handleDelete={handleDelete}
          setIsOpen={setIsOpen}
          setData={setData}
          setModalOpenTop={setModalOpenTop}
          setIsAddUpdateTransactionModal={setIsAddUpdateTransactionModal}
          setIsOpenAgainstEntry={setIsOpenAgainstEntry}
          setIsCheckClick={setIsCheckClick}
          isCheckClick={isCheckClick}
          data={data}
          selectedItems={selectedItems}
          setIsEntryDelete={setIsEntryDelete}
          setDefaultFromName={setDefaultFromName}
          setTransactionType={setTransactionType}
        />}


        {/* Merging Name drawer */}
        {isMergeNameMenuOpen &&
          <CommonDrawer
            open={isMergeNameMenuOpen}
            onClose={handleCloseNameDrawer}
            title={"Merge Name"}
            Transition={Transition}
            content={
              <MergingNaming
                defaultFromName={defaultFromName}
                _getTitleSearchData={_getTitleSearchData}
                handleChange={handleChangeName}
                titleSearchedData={titleSearchedData}
                fromNameValue={fromNameValue}
                toNameValue={toNameValue}
                fromNameOptions={fromNameOptions}
                toNameOptions={toNameOptions}
                handleAddMergeName={handleAddMergeName}
                error={error}
              />
            }
          />
        }
        {/* Search drawer */}
        {isSearch &&
          <CommonDrawer
            title={
              <Box display={"flex"}>
                <CustomTextField
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="search"
                  autoFocus
                  placeholder="Search..."
                  sx={{ mx: 2 }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { color: "white" },
                  }}
                  value={searchText}
                  onChange={(e) => handleChangeSearch(e)}
                />
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setSearchText("")
                    setSelectedButton("allTime");
                    setSelectedTab(0);
                    setIsOpenSearchDate(false);
                    _globalSearch();
                  }}
                  disabled={!searchText}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            }
            Transition={Transition}
            onClose={handleCloseSearch}
            open={isSearch}
            content={
              <>
                <SearchContent
                  searchText={searchText}
                  handleButtonClick={handleButtonClick}
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                  theme={theme}
                  date={searchDate}
                  setDate={setSearchDate}
                  classes={classes}
                  // _globalSearch={_globalSearch}
                  tags={tags}
                  allTransactions={allTransactions}
                  handleClickPdf={handleClickPdf}
                  searchData={searchData}
                  generateHighlightedTitle={generateHighlightedTitle}
                  creditTransactions={creditTransactions}
                  debitTransactions={debitTransactions}
                  isOpen={isOpenSearchDate}
                  setIsOpen={setIsOpenSearchDate}
                  isOpenFrom={isOpenFrom}
                  setIsOpenFrom={setIsOpenFrom}
                  isOpenTo={isOpenTo}
                  setIsOpenTo={setIsOpenTo}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  isOpenMonth={isOpenMonth}
                  setIsOpenMonth={setIsOpenMonth}
                  setIsOpenYear={setIsOpenYear}
                  isOpenYear={isOpenYear}
                  setFilterDate={setFilterDate}
                  filterDate={filterDate}
                  setBothDatesSelected={setBothDatesSelected}
                  bothDatesSelected={bothDatesSelected}
                />
              </>
            }
          />
        }
        {/* {openMonthlyPdf && <CommonDrawer
          open={openMonthlyPdf}
          Transition={Transition}
          onClose={handleCloseMonthlyPdf}
          isPdf={true}
          content={<iframe src={pdfUri} width="99%" height="500px" style={{ overflowX: "hidden" }}></iframe>} />} */}
        {openMonthlyPdf &&
          <CommonDrawer
            title={""}
            Transition={Transition}
            onClose={handleCloseMonthlyPdf}
            open={openMonthlyPdf}
            content={
              <>
                <SearchContent
                  isMonthlyPdf
                  handleButtonClick={handleButtonClick}
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                  theme={theme}
                  date={searchDate}
                  setDate={setSearchDate}
                  classes={classes}
                  // _globalSearch={_globalSearch}
                  tags={tags}
                  allTransactions={allTransactions}
                  handleClickPdf={handleClickPdf}
                  searchData={searchData}
                  generateHighlightedTitle={generateHighlightedTitle}
                  creditTransactions={creditTransactions}
                  debitTransactions={debitTransactions}
                  isOpen={isOpenSearchDate}
                  setIsOpen={setIsOpenSearchDate}
                  isOpenFrom={isOpenFrom}
                  setIsOpenFrom={setIsOpenFrom}
                  isOpenTo={isOpenTo}
                  setIsOpenTo={setIsOpenTo}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  isOpenMonth={isOpenMonth}
                  setIsOpenMonth={setIsOpenMonth}
                  setIsOpenYear={setIsOpenYear}
                  isOpenYear={isOpenYear}
                  setFilterDate={setFilterDate}
                  filterDate={filterDate}
                  pdfUri={pdfUri}
                  setBothDatesSelected={setBothDatesSelected}
                  bothDatesSelected={bothDatesSelected}
                />
              </>
            }
          />}
        {openYearlyPdf &&
          <CommonDrawer
            title={""}
            Transition={Transition}
            onClose={handleCloseYearlyPdf}
            open={openYearlyPdf}
            content={
              <>
                <SearchContent
                  isYearlyPdf
                  handleButtonClick={handleButtonClick}
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                  theme={theme}
                  date={searchDate}
                  setDate={setSearchDate}
                  classes={classes}
                  // _globalSearch={_globalSearch}
                  tags={tags}
                  allTransactions={allTransactions}
                  handleClickPdf={handleClickPdf}
                  searchData={searchData}
                  generateHighlightedTitle={generateHighlightedTitle}
                  creditTransactions={creditTransactions}
                  debitTransactions={debitTransactions}
                  isOpen={isOpenSearchDate}
                  setIsOpen={setIsOpenSearchDate}
                  isOpenFrom={isOpenFrom}
                  setIsOpenFrom={setIsOpenFrom}
                  isOpenTo={isOpenTo}
                  setIsOpenTo={setIsOpenTo}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  isOpenMonth={isOpenMonth}
                  setIsOpenMonth={setIsOpenMonth}
                  setIsOpenYear={setIsOpenYear}
                  isOpenYear={isOpenYear}
                  setFilterDate={setFilterDate}
                  filterDate={filterDate}
                  pdfUri={yearPdfUri}
                  setBothDatesSelected={setBothDatesSelected}
                  bothDatesSelected={bothDatesSelected}
                />
              </>
            }
          />}
      </Grid>

    </>
  );
};

export default Home;

const generateHighlightedTitle = (title, searchText, theme, voucherNo) => {
  const titleChars = title?.split("");
  let highlightedTitle = [];
  let startIndex = 0;
  while (startIndex < title?.length) {
    const searchIndex = title?.toLowerCase()?.indexOf(searchText?.toLowerCase(), startIndex);
    if (searchIndex !== -1) {
      highlightedTitle?.push(title?.slice(startIndex, searchIndex));
      highlightedTitle?.push(<span style={{ color: theme.palette.primary.main, fontWeight: 550 }}>{title?.slice(searchIndex, searchIndex + searchText?.length)}</span>);
      startIndex = searchIndex + searchText?.length;
    } else {
      highlightedTitle?.push(title?.slice(startIndex));
      break;
    }
  }
  if (voucherNo) {
    highlightedTitle.push(` (${voucherNo})`);
  }
  return highlightedTitle;
};
