import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ResponsiveDialog = ({ open, onSave, onClose, title, closeText, saveText, content }) => {

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ padding: '10px 16px' }}>
          {title}
        </DialogTitle>
        <DialogContent sx={{ padding: '0px 16px' }}>
          {content}
        </DialogContent>
        {(onClose || onSave) && <DialogActions>
          <Button onClick={onClose}>{closeText ? closeText : "No"}</Button>
          <Button onClick={onSave} autoFocus>
            {saveText ? saveText : "Yes"}
          </Button>
        </DialogActions>}
      </Dialog>
    </React.Fragment>
  );
}

export default ResponsiveDialog