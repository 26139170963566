import jsPDF from "jspdf";
import 'jspdf-autotable';
import dayjs from 'dayjs';
import { BUCKET_URL } from "../Api/Axios"
import { divideByHundred } from '../Utils/enum';

const SearchPdfGenerator = ({ onPdfGenerated, theme, searchData, isDownload, filterDate, startDate, endDate, selectedButton, selectedCompany }) => {
    const primaryColor = theme.palette.primary.main;
    const bgLightColor = theme.palette.bgLightGreen.main;
    const textColor = theme.palette.common.black;

    const unit = 'pt';
    const size = 'A4';
    const orientation = 'portrait';
    const doc = new jsPDF(orientation, unit, size);

    const pageWidth = doc.internal.pageSize.getWidth();
    const marginLeftRight = 20;
    const usablePageWidth = pageWidth - (2 * marginLeftRight);
    const text = "Day to Day Expenses";
    const textWidth = doc.getTextWidth(text);
    const textX = (pageWidth - textWidth) / 2;
    let textHeight = 50;

    const companyName = selectedCompany;
    const cfAmount = searchData?.carryForwardAmount ? divideByHundred(searchData?.carryForwardAmount) : "0.00";
    const totalBalance = searchData?.totalBalance ? divideByHundred(searchData?.totalBalance) : "0.00";

    // Get the current date and time
    const currentDateTime = dayjs().format('DD-MM-YYYY HH:mm:ss');

    // Add the current date and time
    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    doc.setTextColor(textColor);
    doc.text(currentDateTime, marginLeftRight, 30);

    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');
    doc.setTextColor(primaryColor);
    doc.text(text, textX, textHeight);

    const getMonthName = (date, end) => {
        if (date) {
            if (selectedButton === "month") {
                return dayjs(date).format("MMMM YYYY");
            } else if (selectedButton === "year") {
                return dayjs(date).format("YYYY");
            } else if (selectedButton === "endDate") {
                return `${dayjs(date).format("DD MMMM YYYY")} - ${dayjs(end).format("DD MMMM YYYY")}`;
            }
        } else {
            if (selectedButton === "month") {
                return dayjs().format("MMMM YYYY");
            } else if (selectedButton === "year") {
                return dayjs().format("YYYY");
            } else if (selectedButton === "endDate") {
                return `${dayjs().format("DD MMMM YYYY")} - ${dayjs().format("DD MMMM YYYY")}`;
            }
        }
    };

    const subtitle = selectedButton === "month" ? getMonthName(filterDate?.month) : selectedButton === "year" ? getMonthName(filterDate?.year) : selectedButton === "endDate" ? getMonthName(startDate, endDate) : "";

    const startY = textHeight + 30;

    if (subtitle || companyName) {
        const combinedText = `${subtitle} ${subtitle ? "|" : ""} ${companyName}`;
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal');
        doc.setTextColor(textColor);
        const combinedTextWidth = doc.getTextWidth(combinedText);
        const combinedTextX = (pageWidth - combinedTextWidth) / 2;
        textHeight += 20;
        doc.text(combinedText, combinedTextX, textHeight);
    }

    const columnWidths = "auto";
    const columnWidthTitleDesc = usablePageWidth / 2.5;
    const columnStyles = {
        0: { columnWidth: columnWidths, overflow: 'linebreak' },
        1: { columnWidth: columnWidthTitleDesc, overflow: 'linebreak' },
        2: { columnWidth: columnWidths, overflow: 'linebreak', halign: "right" },
        3: { columnWidth: columnWidths, overflow: 'linebreak', halign: "right" },
        4: { columnWidth: columnWidths, overflow: 'linebreak', halign: "right" },
        5: { columnWidth: columnWidths, overflow: 'linebreak', halign: "right" },
    };

    const headers = [
        ['Date', 'Title & Description', 'Image Link', 'Income (Credit)', 'Expense (Debit)', "Closing Balance"],
    ];

    // Process and sort data by date
    const sortedData = searchData?.response?.sort((a, b) => new Date(a.date) - new Date(b.date))


    // Calculate totals and structure data for the table
    let totalCredit = 0;
    let totalDebit = 0;
    const data = [];

    // Add carry forward row
    if (searchData?.carryForwardAmount) {
        data.push([
            { content: 'c/f', colSpan: 5, styles: { cellPadding: { top: 5, bottom: 5, left: 6 }, halign: 'left', border: 'none' } },
            { content: cfAmount || "0.00", styles: { fontStyle: 'bold', halign: 'right', cellPadding: { top: 5, bottom: 5, left: 0, right: 4, textColor: textColor } } },
        ]);
    }

    sortedData?.forEach(transaction => {
        const date = dayjs(transaction?.date).format("DD-MM-YYYY");
        let title = transaction?.title || "-";
        if (transaction?.voucherNo) {
            title = transaction?.title + " " + `(${transaction?.voucherNo})`
        }
        const description = transaction?.description || '';
        let titleAndDescription;
        if (description) {
            titleAndDescription = `${title}\n${description}`
        } else {
            titleAndDescription = title
        }
        const imageLink = transaction?.image ? { text: "Link", url: BUCKET_URL + transaction.image } : "";
        data?.push([
            { content: date },
            { content: titleAndDescription }, // Combine title and description
            { content: imageLink ? "" : "", },
            { content: transaction?.transactionType === 0 ? divideByHundred(transaction?.amount) : '0.00' },
            { content: transaction?.transactionType === 1 ? divideByHundred(transaction?.amount) : '0.00' },
            { content: transaction?.closingBalance ? divideByHundred(transaction?.closingBalance) : '0.00' },
        ]);
        if (transaction?.transactionType === 0) {
            totalCredit += transaction?.amount;
        } else if (transaction?.transactionType === 1) {
            totalDebit += transaction?.amount;
        }
    });

    data.push([
        { content: '', colSpan: 3, styles: { halign: 'right', fontStyle: 'bold', fillColor: bgLightColor } },
        { content: divideByHundred(totalCredit), styles: { fontStyle: 'bold', halign: 'right', fillColor: bgLightColor, textColor: theme.palette.success.main } },
        { content: divideByHundred(totalDebit), styles: { fontStyle: 'bold', halign: 'right', fillColor: bgLightColor, textColor: theme.palette.error.main } },
    ]);
    data.push([
        { content: '', colSpan: 5, styles: { halign: 'right', fontStyle: 'bold', fillColor: bgLightColor } },
        { content: `Balance = ${totalBalance || 0}/-`, styles: { fontStyle: 'bold', halign: 'right', fillColor: bgLightColor, textColor: theme.palette.secondary.main } },
    ]);

    const headerStyles = {
        fontSize: 10,
        fontStyle: 'bold',
        overflow: 'linebreak',
        fillColor: theme.palette.primary.main,
        textColor: theme.palette.common.white,
    };

    let content = {
        startY: startY,
        head: headers,
        body: data,
        columnStyles: columnStyles,
        theme: 'grid',
        headStyles: headerStyles,
        bodyStyles: { lineColor: [0, 0, 0] },
        margin: { left: marginLeftRight, right: marginLeftRight },
        didDrawCell: (data) => {
            if (data.section === 'body' && data.column.index === 1) {
                const cellContent = data.cell.raw;
                console.log("title cellContent", cellContent);
                if (typeof cellContent?.content === 'string' && cellContent?.content.includes('\n')) {
                    const [title, description] = cellContent?.content.split('\n');
                    console.log("title", title);
                    console.log("title description", description);
                    const cellWidth = data.cell.width;
                    const cellHeight = data.cell.height;

                    const titleY = data.cell.y + 10; // Adjust as necessary
                    const descriptionY = titleY + 12; // Adjust as necessary

                    // Save current font settings
                    const currentFontSize = doc.internal.getFontSize();
                    const currentFont = doc.internal.getFont();
                    const currentTextColor = doc.getTextColor();

                    // Render description
                    doc.setFontSize(currentFontSize);
                    doc.setFont(undefined, 'normal');
                    doc.setTextColor("#136d6f");
                    doc.text(description, data.cell.x + 5, descriptionY + 3);

                    // Restore font settings
                    doc.setFontSize(currentFontSize);
                    doc.setFont(currentFont.fontName, currentFont.fontStyle);
                    doc.setTextColor(currentTextColor);
                }
            }
            if (data.section === 'body' && data.column.index === 2) { // Change the index to match the Image Link column
                const linkData = sortedData[data.row.index - 1]?.image;
                if (linkData) { // Check if cell contains the link data
                    const url = BUCKET_URL + linkData; // Construct the URL
                    const cellWidth = data.cell.width - data.cell.padding('left') - data.cell.padding('right');
                    const textX = data.cell.x + (cellWidth / 2); // Center the text horizontally
                    const textY = data.cell.y + (data.cell.height / 2) + 2.5; // Center the text vertically

                    doc.setTextColor(0, 0, 255);
                    doc.text('Link', textX, textY, { url: url, align: 'center' });

                    // Register link
                    doc.link(data.cell.x, data.cell.y, data.cell.width, data.cell.height, { url: url });
                }
            }
        }
    };

    // Add the table to the document
    doc.autoTable(content);

    // Initiate download of the PDF file
    doc.save("Day-To-Day-Expenses.pdf");

};

export default SearchPdfGenerator;