import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';
import { divideByHundred } from '../Utils/enum';


const YearlyPdfGenerator = ({ onPdfGenerated, theme, searchData, transactionByYear, isDownload, filterDate, startDate, endDate, selectedButton, selectedCompany }) => {

    const getMonthName = (monthNumber) => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return monthNames[monthNumber - 1];
    };

    const primaryColor = theme.palette.primary.main;
    const bgLightColor = theme.palette.bgLightGreen.main;
    const textColor = theme.palette.common.black;

    const unit = 'pt';
    const size = 'A4';
    const orientation = 'portrait';
    const doc = new jsPDF(orientation, unit, size);

    const pageWidth = doc.internal.pageSize.getWidth();
    const marginLeftRight = 20;
    const usablePageWidth = pageWidth - (2 * marginLeftRight);
    const text = 'Day to Day Expenses';
    const textWidth = doc.getTextWidth(text);
    const textX = (pageWidth - textWidth) / 2;
    let textHeight = 50;

    const companyName = selectedCompany;
    const cfAmount = searchData?.carryForwardAmount ? divideByHundred(searchData?.carryForwardAmount) : "0.00";
    const totalBalance = searchData?.totalBalance ? divideByHundred(searchData?.totalBalance) : "0.00";

    // Get the current date and time
    const currentDateTime = dayjs().format('DD-MM-YYYY HH:mm:ss');

    // Add the current date and time
    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    doc.setTextColor(textColor);
    doc.text(currentDateTime, marginLeftRight, 30);

    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');
    doc.setTextColor(primaryColor);
    doc.text(text, textX, textHeight);

    const getMonthType = (date, end) => {
        if (selectedButton === "month") {
            return dayjs(date).format("MMMM YYYY");
        } else if (selectedButton === "year") {
            return dayjs(date).format("YYYY");
        } else if (selectedButton === "endDate") {
            return `${dayjs(date).format("DD MMMM YYYY")} - ${dayjs(end).format("DD MMMM YYYY")}`;
        }
    };

    var subtitle = selectedButton === "month" ? getMonthType(filterDate?.month) : selectedButton === "year" ? getMonthType(filterDate?.year) : selectedButton === "endDate" ? getMonthType(startDate, endDate) : "";

    if (subtitle || companyName) {
        const combinedText = `${subtitle} ${subtitle ? "|" : ""} ${companyName}`;
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal');
        doc.setTextColor(textColor);
        const combinedTextWidth = doc.getTextWidth(combinedText);
        const combinedTextX = (pageWidth - combinedTextWidth) / 2;
        textHeight += 20;
        doc.text(combinedText, combinedTextX, textHeight);
    }

    const columnWidths = usablePageWidth / 4;
    const columnStyles = {
        0: { columnWidth: columnWidths, overflow: 'linebreak' },
        1: { columnWidth: columnWidths, overflow: 'linebreak', halign: 'right' },
        2: { columnWidth: columnWidths, overflow: 'linebreak', halign: 'right' },
        3: { columnWidth: columnWidths, overflow: 'linebreak', halign: 'right' },
    };

    const headers = [
        ['Month', 'Income (Credit)', 'Expense (Debit)', 'Balance'],
    ];


    // Initialize data array with c/f row if carryForwardAmount exists
    let data = [];
    if (searchData?.carryForwardAmount) {
        data.push([
            { content: 'C/F', styles: { halign: 'left' } },
            { content: '', styles: { halign: 'right' } },
            { content: '', styles: { halign: 'right' } },
            { content: cfAmount || '0.00', styles: { halign: 'right' } }
        ]);
    }

    // Populate data array with the transactions
    searchData.response.forEach(transaction => {
        const monthName = getMonthName(transaction.month);
        data.push([
            { content: monthName, styles: { halign: 'left' } },
            { content: transaction.credit ? divideByHundred(transaction.credit) : "0.00", styles: { halign: 'right' } },
            { content: transaction.debit ? divideByHundred(transaction.debit) : "0.00", styles: { halign: 'right' } },
            { content: transaction.totalBalance ? divideByHundred(transaction.totalBalance) : "0.00", styles: { halign: 'right' } },
        ]);
    });

    data.push([
        { content: '', colSpan: 1, styles: { border: 'none', fillColor: bgLightColor, textColor: textColor } },
        { content: searchData.creditAmount ? divideByHundred(searchData.creditAmount) : '0.00', styles: { halign: 'right', fontStyle: 'bold', border: 'none', fillColor: bgLightColor, textColor: textColor } },
        { content: searchData.debitAmount ? divideByHundred(searchData.debitAmount) : '0.00', styles: { halign: 'right', fontStyle: 'bold', border: 'none', fillColor: bgLightColor, textColor: textColor } },
        { content: totalBalance || '0.00', styles: { halign: 'right', fontStyle: 'bold', border: 'none', fillColor: bgLightColor, textColor: textColor } }
    ]);

    const headerStyles = {
        fontSize: 10,
        fontStyle: 'bold',
        overflow: 'linebreak',
        fillColor: theme.palette.primary.main,
        textColor: theme.palette.common.white,
    };

    let content = {
        startY: textHeight + 30,
        head: headers,
        body: data,
        columnStyles: columnStyles,
        theme: 'grid',
        headStyles: headerStyles,
        bodyStyles: { lineColor: [0, 0, 0] },
        margin: { left: marginLeftRight, right: marginLeftRight },
    };

    doc.autoTable(content);

    const pdfDataUri = doc.output('datauristring');
    onPdfGenerated(pdfDataUri);

    if (isDownload) {
        doc.save('Day-To-Day-Expenses.pdf');
    }
};


export default YearlyPdfGenerator;
