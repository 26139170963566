import React from "react";
import { useAppContext } from "../Context/Context";
import { Backdrop, CircularProgress } from "@mui/material";

function Loader() {
  const { loader } = useAppContext();
  return (
    <>
      {loader ? (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </>
  );
}

export default Loader;
