import { Box, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import dayjs from 'dayjs';
import { divideByHundred } from '../../Utils/enum';

const CommonDatePicker = ({ date, balance, currency, daily, monthly, yearly, handelNextDate, handelPrevDate, onClick }) => {
  const theme = useTheme()
  const todayDate = dayjs();

  return (
    <>
      {daily ?
        <Grid container
          bgcolor={theme.palette.primary.main} borderRadius={1}
          color={theme.palette.bgLightWhite.main}
          justifyContent={"space-between"}
        >
          <Grid item xs={6}>
            <Box display={"flex"} alignItems={"center"} >
              <IconButton onClick={handelPrevDate}>
                <KeyboardArrowLeftIcon sx={{ color: theme.palette.bgLightWhite.main }} />
              </IconButton>
              <Box display={"flex"} alignItems={"center"} gap={1} onClick={onClick} sx={{ cursor: "pointer" }}>
                <Typography variant='h6' sx={{ border: todayDate.isSame(date.days, 'day') ? "1px solid white" : "", padding: "0px 5px 0px", borderRadius: todayDate.isSame(date.days, 'day') ? "10px" : "" }}>{dayjs(date?.days).format('DD')}</Typography>
                <Box>
                  <Typography variant='body1'>{dayjs(date?.days).format('MMMM, YYYY')}</Typography>
                  <Typography variant='body1'>{dayjs(date?.days).format('dddd')}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display={"flex"} alignItems={"center"} textAlign={'end'} justifyContent="flex-end">
              <Box>
                <Typography variant='body1'>Balance</Typography>
                <Typography variant='body1'>
                  {balance ? `${divideByHundred(balance)}` : "₹0.00"}
                </Typography>
              </Box>
              <IconButton onClick={handelNextDate}>
                <KeyboardArrowRightIcon sx={{ color: theme.palette.bgLightWhite.main }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid >
        :
        <Grid container
          display={"flex"}
          alignItems={"center"}
          bgcolor={theme.palette.primary.main} borderRadius={1}
          color={theme.palette.bgLightWhite.main}
          justifyContent={"space-between"}
        >
          <IconButton onClick={handelPrevDate}>
            <KeyboardArrowLeftIcon sx={{ color: theme.palette.bgLightWhite.main }} />
          </IconButton>
          <Box display={"flex"} alignItems={"center"} gap={1} onClick={onClick} sx={{ cursor: "pointer" }}>
            {
              monthly ? <Typography variant='body1'>{dayjs(date?.month).format('MMMM YYYY')}</Typography> :
                <Typography variant='body1'>{dayjs(date?.year).format('YYYY')}</Typography>
            }
          </Box>
          <IconButton onClick={handelNextDate}>
            <KeyboardArrowRightIcon sx={{ color: theme.palette.bgLightWhite.main }} />
          </IconButton>
        </Grid>}
    </>
  )
}

export default CommonDatePicker
