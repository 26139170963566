import {
  AppBar,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
  InputAdornment,
  Divider,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "tss-react/mui";
import { useAppContext } from "../Context/Context";
import axios from "../Api/Axios";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Regex } from "../Utils/regex";
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@emotion/react";


const useStyles = makeStyles()((theme) => {
  return {
    datePicker: {
      borderBottom: "2px solid #5D5FEF",
      outline: "none",
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
  };
});

const CreateProfileModal = ({
  setOpenCreateProfileModa,
  profileData,
  setProfileData,
  handleChangeForCreateProfile,
  _getAllUserProfiles,
  isProfileEdit,
  companyList, selectedCompanies, setSelectedCompanies,
  setIsProfileEdit
}) => {
  const theme = useTheme()
  const { OnUpdateError, user, toggleLoader } = useAppContext();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  console.log("errors", errors);

  const Validation = () => {
    let newErrors = {};
    let isValid = true;
    console.log("profileData", profileData);

    if (!profileData?.fullName) {
      isValid = false;
      newErrors['fullName'] = `Full Name is required.`;
    }
    if (!profileData?.email) {
      isValid = false;
      newErrors['email'] = `Email is required.`;
    } else if (!profileData?.email.match(Regex.emailRegex)) {
      isValid = false;
      newErrors['validateEmail'] = `Enter valid email.`;
    }
    // if (!profileData?.phoneNumber) {
    //   isValid = false;
    //   newErrors['phoneNumber'] = `Phone Number is required.`;
    // } else if (!profileData?.phoneNumber.match(Regex.mobileNumberRegex)) {
    //   isValid = false;
    //   newErrors['validatePhoneNumber'] = `Enter valid phone number.`;
    // }
    if (isProfileEdit ? false : !profileData?.password) {
      isValid = false;
      newErrors['password'] = `Password is required.`;
    } else if (isProfileEdit ? false : !profileData?.password.match(Regex.passwordRegex)) {
      isValid = false;
      newErrors['validatePassword'] = `Enter valid password.`;
    }
    if (isProfileEdit ? false : !profileData?.confirmPassword) {
      isValid = false;
      newErrors['confirmPassword'] = `Confirm Password is required.`;
    } else if (isProfileEdit ? false : !profileData?.confirmPassword.match(Regex.passwordRegex)) {
      isValid = false;
      newErrors['validateConfirmPassword'] = `Enter valid confirm password.`;
    } else if (isProfileEdit ? false : profileData?.password !== profileData?.confirmPassword) {
      isValid = false;
      newErrors['matchPwdAndConfirmPwd'] = "Password and Confirm Password must be same.";
    }
    // if (!profileData?.dateOfBirth) {
    //   isValid = false;
    //   newErrors['dateOfBirth'] = `Date of Birth is required.`;
    // }
    // if (!profileData?.gender) {
    //   isValid = false;
    //   newErrors['gender'] = `Gender is required.`;
    // }
    if (profileData?.userType != 0 && selectedCompanies?.length === 0) {
      isValid = false;
      newErrors['companies'] = `At least one company must be selected.`;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleTogglePasswordVisibility = (isPassword) => {
    if (isPassword) {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleSubmit = async () => {
    if (Validation()) {
      const body = {
        fullName: profileData?.fullName,
        email: profileData?.email,
        // phoneNumber: profileData?.phoneNumber,
        // dateOfBirth: profileData?.dateOfBirth,
        // gender: profileData?.gender,
        permissions: profileData?.permissions || [],
        userType: 1,
        companyList: selectedCompanies
      };
      if (isProfileEdit) { body._id = profileData?._id };
      if (!isProfileEdit) { body.password = profileData?.password }
      toggleLoader();
      await axios
        .post(`admin/${isProfileEdit ? "update" : "signup"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            _getAllUserProfiles();
            setProfileData({
              fullName: "",
              email: "",
              // phoneNumber: "",
              password: "",
              confirmPassword: "",
              // dateOfBirth: "",
              // gender: "",
              permissions: [],
            });
            setOpenCreateProfileModa(false);
            setIsProfileEdit(false);
            setSelectedCompanies([]);
          }
        })
        .catch((err) => {
          toast.error(err?.message);
          OnUpdateError(err?.message);
        }).finally(() => {
          toggleLoader();
        })
    }
  };
  const handleCompanySelection = (id) => {
    setSelectedCompanies((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((companyId) => companyId !== id)
        : [...prevSelected, id]
    );
  };
  return (
    <>
      <Grid container spacing={2} sx={{ scroll: "auto" }} mt={0.5}>
        <Grid item xs={6}>
          <TextField
            label="Full Name"
            variant="filled"
            size="small"
            name="fullName"
            type="text"
            value={profileData?.fullName || ''}
            onChange={(e) => handleChangeForCreateProfile(e)}
            focused
            fullWidth
            error={errors['fullName'] !== undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email"
            variant="filled"
            size="small"
            name="email"
            value={profileData?.email}
            type="email"
            onChange={(e) => handleChangeForCreateProfile(e)}
            focused
            fullWidth
            helperText={errors['validateEmail']}
            error={errors['email'] !== undefined || errors['validateEmail'] !== undefined}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            label="Phone number"
            variant="filled"
            size="small"
            name="phoneNumber"
            value={profileData?.phoneNumber}
            type="number"
            onChange={(e) => handleChangeForCreateProfile(e)}
            focused
            fullWidth
            helperText={errors['validatePhoneNumber']}
            error={errors['phoneNumber'] !== undefined || errors['validatePhoneNumber'] !== undefined}
          />
        </Grid> */}
        {/* <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth *"
              sx={{ width: "100%" }}
              value={isProfileEdit ? dayjs(profileData?.dateOfBirth) : null}
              onChange={(newValue) =>
                setProfileData({ ...profileData, dateOfBirth: newValue })
              }
              slotProps={{
                textField: {
                  variant: "filled",
                  size: "small",
                  focused: "focused",
                  error: !!errors['dateOfBirth'],
                },
              }}
            />
          </LocalizationProvider>
        </Grid> */}
        {!isProfileEdit &&
          <>
            <Grid item xs={6}>
              <TextField
                variant="filled"
                type={showPassword ? "text" : "password"}
                label="Password"
                size="small"
                name="password"
                value={profileData?.password}
                onChange={(e) => handleChangeForCreateProfile(e)}
                fullWidth
                focused
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => handleTogglePasswordVisibility(true)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors['validatePassword']}
                error={errors['password'] !== undefined || errors['validatePassword'] !== undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="filled"
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm password"
                name="confirmPassword"
                size="small"
                value={profileData?.confirmPassword}
                onChange={(e) => handleChangeForCreateProfile(e)}
                fullWidth
                focused
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => handleTogglePasswordVisibility(false)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors['validateConfirmPassword']}
                error={errors['confirmPassword'] !== undefined || errors['validateConfirmPassword'] !== undefined}
              />
            </Grid>
          </>
        }
        {errors['matchPwdAndConfirmPwd'] &&
          <Grid item xs={12}>
            <Typography variant="body2" fontSize={12} textAlign={"start"} color={"error"}>{errors["matchPwdAndConfirmPwd"]}</Typography>
          </Grid>
        }
        {/* <Grid item xs={12}>
          <FormControl required error={!errors['gender']}>Gender:</FormControl>
          <RadioGroup onChange={(e) => handleChangeForCreateProfile(e)} name="gender" value={profileData?.gender} row          >
            <FormControlLabel value="Male" control={<Radio component={'div'} />} label="Male" />
            <FormControlLabel value="Female" control={<Radio />} label="Female" />
          </RadioGroup>
          <Typography variant="body2" fontSize={12} textAlign={"start"} color={"error"} ml={0.5}>
            {errors['gender'] && errors['gender']}
          </Typography>
        </Grid> */}
        <Grid item xs={12} >
          <FormControl required>Permissions:</FormControl>
          <FormGroup row>
            <FormControlLabel control={<Checkbox checked={profileData.permissions.includes(1)} disabled={profileData?.userType == 0 ? true : false} onChange={(e) => handleChangeForCreateProfile(e)} name="create" value={1} />} label="Create" />
            <FormControlLabel control={<Checkbox checked={profileData.permissions.includes(3)} disabled={profileData?.userType == 0 ? true : false} onChange={(e) => handleChangeForCreateProfile(e)} name="update" value={3} />} label="Update" />
            <FormControlLabel control={<Checkbox checked={profileData.permissions.includes(4)} disabled={profileData?.userType == 0 ? true : false} onChange={(e) => handleChangeForCreateProfile(e)} name="delete" value={4} />} label="Delete" />
            {/* <FormControlLabel control={<Checkbox checked onChange={(e) => handleChangeForCreateProfile(e)} name="view" value={2} disabled />} label="View" /> */}
            <FormControlLabel control={<Checkbox checked={profileData.permissions.includes(5)} disabled={profileData?.userType == 0 ? true : false} onChange={(e) => handleChangeForCreateProfile(e)} name="delete" value={5} />} label="Is Voucher" />
            <FormControlLabel control={<Checkbox checked={profileData.permissions.includes(6)} disabled={profileData?.userType == 0 ? true : false} onChange={(e) => handleChangeForCreateProfile(e)} name="delete" value={6} />} label="Is Against" />
          </FormGroup>
          <Typography variant="body2" fontSize={12} textAlign={"start"} color={"error"} ml={0.5}>
            {errors['permissions'] && errors['permissions']}
          </Typography>
        </Grid>
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={12}>
            <FormControl>Companies:</FormControl>
          </Grid>
          {companyList?.length > 0 && companyList?.map((e) => {
            const isSelected = selectedCompanies.includes(e?._id);
            return (
              <Grid item key={e?._id}>
                <Chip
                  size="small"
                  variant={isSelected ? "filled" : "outlined"}
                  sx={{ padding: 1 }}
                  label={e?.name}
                  onClick={() => handleCompanySelection(e?._id)}
                  color="primary"
                  disabled={profileData.userType == 0 ? true : false}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Typography variant="body2" fontSize={12} textAlign={"start"} color={"error"} ml={0.5}>
              {errors['companies'] && errors['companies']}
            </Typography>
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"center"} mt={5}>
            <Button variant="contained" onClick={() => handleSubmit()} disabled={isProfileEdit && profileData?.userType === 0}>
              {isProfileEdit ? "Update" : "Create"} Profile
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateProfileModal;
