import React, { useEffect } from "react";
import { Autocomplete, Avatar, Box, Button, Drawer, Fab, Grid, IconButton, TextField, Typography, useTheme, } from "@mui/material";
import { styled } from '@mui/material/styles';
import { makeStyles } from "tss-react/mui";
import DualValueChip from "./DualValueChip";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { BUCKET_URL } from "../../Api/Axios";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { divideByHundred, permissions } from "../../Utils/enum";
import { capitalize } from "../../Utils/handler";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const useStyles = makeStyles()((theme) => {
  return {
    gridItem: {
      "& .MuiGrid-item": {
        padding: "0px !important",
      },
    },
    gridContainer: {
      "& .MuiGrid-container": {
        margin: "0px !important",
      },
    },
  };
});

const AddUpdateTransactionModel = ({
  data,
  handleTransactionType,
  isAddUpdateTransactionModal,
  handleChange,
  transactionType,
  user,
  error,
  handleUpload,
  handleDeleteFile,
  titleSearchedData,
  setData,
  handleModalForImage,
  setIsAddUpdateTransactionModal,
  onSubmit,
  handleInputChange,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const isVoucherAndNotUpdate = user?.permissions?.includes(permissions?.isVoucher) && !user?.permissions?.includes(permissions?.update)
  return (
    <>
      <Drawer anchor="bottom" open={isAddUpdateTransactionModal} onClose={() => { setIsAddUpdateTransactionModal(false); setData({}) }}>
        <Grid container xs={12} p={2}>
          <Grid item xs={12} mb={1}>
            <DualValueChip
              transactionType={transactionType}
              handleTransactionType={handleTransactionType}
            />
          </Grid>
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                size="small"
                name="title"
                id="free-solo-2-demo"
                disableClearable
                autoSelect
                // blurOnSelect="touch"
                options={titleSearchedData.length > 0 ? titleSearchedData?.map((e) => capitalize(e)) : []}
                value={data?.title || ""}
                onChange={(e, value) => handleInputChange("title", value)}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                disabled={isVoucherAndNotUpdate}
                inputValue={data?.title || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter Title"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                    variant="filled"
                    focused
                    name="title"
                    onChange={(e) => handleInputChange("title", e.target.value)}
                    value={data?.title || ""}
                    error={Boolean(error["title"])}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Amount"
                variant="filled"
                size="small"
                type="text"
                name="amount"
                value={data?.formattedAmount || ""}
                onChange={(e) => handleChange(e)}
                focused
                fullWidth
                inputProps={{ pattern: "[0-9]*" }}
                disabled={isVoucherAndNotUpdate}
                error={error["amount"] || Boolean(error["amountLength"])}
                helperText={error["amountLength"]}
              />
            </Grid>
            {user?.userType != 0 && user?.permissions?.includes(permissions.isVoucher) && <Grid item xs={6}>
              <TextField
                label="Voucher no"
                variant="filled"
                size="small"
                name="voucherNo"
                value={data?.voucherNo || ''}
                onChange={(e) => handleChange(e)}
                focused
                fullWidth
                error={Boolean(error["voucherNoLength"])}
                helperText={error["voucherNoLength"]}
              />
            </Grid>}
            <Grid item xs={6}>
              <Box width={'100%'} display={"flex"} alignItems={"center"} justifyContent={'center'} mt={0}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  startIcon={<CloudUploadIcon style={{ color: theme.palette.bgLightWhite.main }} />}
                  name="image"
                  onChange={(e) => handleUpload(e?.target?.files)}
                  onClick={(e) => e.target.value = null}
                  disabled={isVoucherAndNotUpdate}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" name="image" accept="image/png, image/jpeg, image/jpg" />
                </Button>
              </Box>
            </Grid>
            {data?.image && (<Grid item xs={6}>
              <Box display={'flex'} gap={1} justifyContent={'center'}>
                <Box
                  component={'img'}
                  sx={{ width: '60%', height: 60, borderRadius: 2, cursor: "pointer", border: `1px dashed ${theme.palette.primary.main}` }}
                  src={BUCKET_URL + data?.image}
                  alt={data?.image ? data?.image : "/"}
                  onClick={handleModalForImage}
                />
                <IconButton
                  color="error"
                  onClick={(e) => { handleDeleteFile('transaction') }}
                  disabled={isVoucherAndNotUpdate}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>)}
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="filled"
                size="small"
                type="text"
                name="description"
                value={data?.description || ''}
                onChange={(e) => handleChange(e)}
                focused
                fullWidth
                // error={error["description"] !== undefined}
                multiline
                rows={2}
                disabled={isVoucherAndNotUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"end"}>
                <Fab
                  size="medium"
                  color="primary"
                  sx={{ color: theme.palette.bgLightWhite.main }}
                  onClick={onSubmit}
                >
                  <DoneRoundedIcon />
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Drawer >
    </>
  );
};

export default AddUpdateTransactionModel;
