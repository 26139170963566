import React, { useReducer } from "react";
import { Box, Grid, useTheme, Fab, TextField, Typography, IconButton, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CommonDatePicker from "../Common/CommonDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { capitalize } from "../../Utils/handler";
import { divideByHundred, permissions, preventSelectText } from "../../Utils/enum";
import { useAppContext } from "../../Context/Context";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ImageIcon from '@mui/icons-material/Image';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const useStyles = makeStyles()((theme) => {
  return {
    gridRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center'
    },
    scrollebleItem: {
      maxHeight: `calc(0.5 * ${window?.innerHeight}px)`,
      minHeight: `calc(0.77 * ${window?.innerHeight}px)`,
      overflowY: "auto",
      width: "100%",
    },
  };
});
const Daily = ({
  date,
  setDate,
  handelNextDate,
  handelPrevDate,
  toggleDrawerBottom,
  onClick,
  transactionByDate,
  handlePressStart,
  modalOpenTop,
  isOpen,
  setIsOpen,
  setIsCheckClick,
  isCheckClick,
  handleCopy,
  selectedItems,
  setModalOpenTop,
  handleDailyModalForImage,
  clickedImageItemId,
  setIsLongPress,
  handleTouchEnd,
  setPressTimer,
  setSelectedItems,
  loading
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { user, deviceInfo } = useAppContext();
  const [showDescription, setShowDescription] = React.useState(null);

  const handleDescriptionToggle = (id) => {
    setShowDescription(prev => prev === id ? null : id);
  };

  const selectedItemsIds = selectedItems?.map((e) => e?._id)

  const handleDateChange = (newValue) => {
    console.log(newValue, "newValue");

    setDate({ ...date, days: newValue });

    if (modalOpenTop && isCheckClick.copy) {
      handleCopy(newValue);
    }

    setModalOpenTop(false);
  };


  console.log(selectedItems, "selectedItems")
  return (
    <>
      <Grid item container spacing={0} xs={12} position={"relative"} style={preventSelectText}>
        <Grid item xs={12}>
          <CommonDatePicker
            date={date}
            setDate={setDate}
            handelNextDate={handelNextDate}
            handelPrevDate={handelPrevDate}
            balance={transactionByDate?.totalBalance}
            daily
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ position: "absolute", zIndex: -1, top: 0 }}>
              <DesktopDatePicker
                className={classes.dateBox}
                inputFormat="MM/DD/YYYY"
                value={dayjs(date?.days)}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} size="small" />}
                open={isOpen}
                closeOnSelect
                formatDensity="dense"
                sx={{ visibility: "hidden" }}
                onClose={() => {
                  setIsOpen((isOpen) => !isOpen);
                  setIsCheckClick({
                    add: false,
                    edit: false,
                    copy: false
                  });
                  setSelectedItems([])
                  setModalOpenTop(false);
                  setIsLongPress(false);
                  setPressTimer(null);
                }}
              />
            </div>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} className={classes.scrollebleItem}>
          <Grid item xs={12} mt={1}>
            <Box className={classes.gridRow} paddingRight={1}>
              <Typography variant="body1">C/F</Typography>
              <Typography variant="body1">
                {transactionByDate?.carryForwardAmount ? `${divideByHundred(transactionByDate?.carryForwardAmount)}` : "₹0.00"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Box
              bgcolor={theme.palette.grey[200]}
              className={classes.gridRow}
              p={0.5}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Total Income (Credit)
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {transactionByDate?.totalCreditAmount ? divideByHundred(transactionByDate?.totalCreditAmount) : "0.00"}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
          >
            {transactionByDate?.creditDetails?.length > 0 ? (
              transactionByDate?.creditDetails?.
                // sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.
                map((ele, index) => {
                  return (
                    <div
                      key={index}
                      onTouchStart={selectedItems?.length === 0 ? (e) => handlePressStart(ele, e, "daily") : null}
                      onTouchEnd={handleTouchEnd}
                      onMouseLeave={handleTouchEnd}
                      onTouchCancel={handleTouchEnd}
                      onClick={deviceInfo?.os?.name == "Windows" || deviceInfo?.os?.name == "Mac OS" ? () => onClick(ele) : (selectedItems?.length > 0 ? () => onClick(ele) : null)}
                      style={{
                        cursor: "pointer",
                        background: selectedItemsIds.includes(ele?._id) && modalOpenTop ? theme.palette.bgLightGreen.main : "",
                        padding: "6px 4px",
                        marginTop: theme.spacing(0.2),
                        marginBottom: theme.spacing(0.2),
                        borderRadius: selectedItemsIds.includes(ele?._id) && modalOpenTop ? "4px" : "",
                      }}
                    >
                      <Box key={index} className={classes.gridRow} paddingRight={1}>
                        <Typography component={Box} variant="body1" textTransform={'capitalize'} width={"215px"} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }} lineHeight={1}>
                          {`${ele?.title}`}
                          <Typography component={"span"} lineHeight={1} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }}>
                            {ele?.description && <IconButton color="default" disabled={selectedItemsIds?.length > 0} onClick={(e) => { e?.stopPropagation(); handleDescriptionToggle(ele?._id); }} sx={{ padding: 0, wordBreak: "break-word", wordWrap: 'break-word' }}>
                              <MarkUnreadChatAltIcon fontSize="small" sx={{ color: showDescription === ele?._id ? 'lightGray' : theme.palette.info.main, mt: 0.3, ml: 0.5 }} />
                            </IconButton>}
                            {ele?.isAssignCount > 0 &&
                              <IconButton style={{ padding: 0, marginLeft: 1 }}>
                                <PublishedWithChangesIcon style={{ fontSize: '15px' }} color="primary" />
                              </IconButton>
                            }
                            {ele?.image &&
                              <IconButton color="default" disabled={selectedItemsIds?.length > 0} onClick={(e) => { e?.stopPropagation(); handleDailyModalForImage(ele) }} sx={{ padding: 0, wordBreak: "break-word", wordWrap: 'break-word' }}>
                                <ImageIcon fontSize="small" sx={{ color: clickedImageItemId === ele?._id ? "lightGray" : theme.palette.info.main, ml: 0.5, }} />
                              </IconButton>
                            }
                            {ele?.voucherNo &&
                              <Typography component={"span"} fontSize={'11px'} color={theme.palette.primary.main} ml={0.5} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }}>
                                {`(${ele?.voucherNo})`}
                              </Typography>
                            }
                          </Typography>
                        </Typography>

                        <Typography variant="body1">
                          {ele?.amount ? divideByHundred(ele?.amount) : "0.00"}
                        </Typography>
                      </Box>

                      {showDescription === ele?._id && (
                        <Box width={'350px'}>
                          <Typography variant="caption" component={'p'} style={{ marginTop: 4 }} noWrap lineHeight={'0.8rem'}>
                            {capitalize(ele?.description)}
                          </Typography>
                        </Box>
                      )}

                    </div>
                  )
                })
            ) : (
              <Box textAlign={"center"} width={"100%"} py={1}>
                <Typography variant="body1" color={'lightgray'}>
                  Tap on '+' to add new item and long press an entry to edit.
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              bgcolor={theme.palette.grey[200]}
              className={classes.gridRow}
              p={0.5}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Total Expense (Debit)
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {transactionByDate?.totalDebitAmount ? divideByHundred(transactionByDate?.totalDebitAmount) : "0.00"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} display={"flex"} flexDirection={"column"}>
            {transactionByDate?.debitDetails?.length > 0 ? (
              transactionByDate?.debitDetails?.
                // sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.
                map((ele, index) => {
                  return (
                    <div
                      key={index}
                      onTouchStart={selectedItems?.length === 0 ? (e) => handlePressStart(ele, e, "daily") : null}
                      onTouchEnd={handleTouchEnd}
                      onMouseLeave={handleTouchEnd}
                      onTouchCancel={handleTouchEnd}
                      onClick={deviceInfo?.os?.name == "Windows" || deviceInfo?.os?.name == "Mac OS" ? () => onClick(ele) : (selectedItems?.length > 0 ? () => onClick(ele) : null)}
                      style={{
                        cursor: "pointer",
                        background: selectedItemsIds.includes(ele?._id) && modalOpenTop ? theme.palette.bgLightGreen.main : "",
                        padding: "6px 4px",
                        marginTop: theme.spacing(0.2),
                        marginBottom: theme.spacing(0.2),
                        borderRadius: selectedItemsIds.includes(ele?._id) && modalOpenTop ? "4px" : "",
                      }}
                    >
                      <Box
                        key={index}
                        className={classes.gridRow}
                        paddingRight={1}
                        style={preventSelectText}
                      >
                        <Typography component={Box} variant="body1" textTransform={'capitalize'} width={"200px"} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }} lineHeight={1}>
                          {`${ele?.title}`}
                          <Typography component={"span"} lineHeight={1} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }}>
                            {ele?.description && <IconButton color="default" disabled={selectedItemsIds?.length > 0} onClick={(e) => { e?.stopPropagation(); handleDescriptionToggle(ele?._id); }} sx={{ padding: 0, wordBreak: "break-word", wordWrap: 'break-word' }}>
                              <MarkUnreadChatAltIcon fontSize="small" sx={{ color: showDescription === ele?._id ? 'lightGray' : theme.palette.info.main, mt: 0.3, ml: 0.5 }} />
                            </IconButton>}
                            {ele?.image &&
                              <IconButton color="default" disabled={selectedItemsIds?.length > 0} onClick={(e) => { { handleDailyModalForImage(ele); e?.stopPropagation(); } }} sx={{ padding: 0, wordBreak: "break-word", wordWrap: 'break-word' }}>
                                <ImageIcon fontSize="small" sx={{ color: clickedImageItemId === ele?._id ? "lightGray" : theme.palette.info.main, ml: 0.5 }} />
                              </IconButton>
                            }
                            {ele?.voucherNo &&
                              <Typography component={"span"} fontSize={'11px'} color={theme.palette.primary.main} ml={0.5} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }}>
                                {`(${ele?.voucherNo})`}
                              </Typography>
                            }
                          </Typography>
                        </Typography>

                        <Typography variant="body1">
                          {ele?.amount ? divideByHundred(ele?.amount) : "0.00"}
                        </Typography>
                      </Box>

                      {showDescription === ele?._id && (
                        <Box width={'350px'}>
                          <Typography variant="caption" component={'p'} style={{ marginTop: 4 }} noWrap lineHeight={'0.8rem'}>
                            {ele?.description}
                          </Typography>
                        </Box>
                      )}

                    </div>
                  )
                })
            ) : (
              <Box textAlign={"center"} width={"100%"} py={1}>
                <Typography variant="body1" color={'lightgray'}>
                  Tap on '+' to add new item and long press an entry to edit.
                </Typography>
              </Box>
            )}
          </Grid>
          <Box height={"50px"} >
          </Box>
        </Grid>

        <Grid item xs={12}
          position={"absolute"} bottom={0} right={4}
        >
          <Box >
            {user?.permissions?.includes(permissions.create) &&
              <Fab
                size="medium"
                aria-label="add"
                color="primary"
                onClick={() => { toggleDrawerBottom(); setIsCheckClick({ ...isCheckClick, add: true }) }}
                sx={{ boxShadow: "none", color: theme.palette.bgLightWhite.main }}
              >
                <AddIcon fontSize="medium" />
              </Fab>
            }
          </Box>
        </Grid>
      </Grid >
    </>
  );
};

export default Daily;
