import React from "react";
import { Box, Fab, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import CommonDatePicker from "../Common/CommonDatePicker";
import { makeStyles } from "tss-react/mui";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useAppContext } from "../../Context/Context";
import { divideByHundred } from "../../Utils/enum";

const useStyles = makeStyles()((theme) => {
  return {
    cellStyle: { padding: "5px 10px" },
    scrollebleItem: {
      maxHeight: `calc(0.5 * ${window?.innerHeight}px)`,
      minHeight: `calc(0.77 * ${window?.innerHeight}px)`,
      overflowY: "auto",
      width: "100%",
    },
  }
});

const Yearly = ({ date, setDate, handelNextDate, handelPrevDate, transactionByYear, downloadYearlyPdf, searchData }) => {
  const { classes } = useStyles()
  const theme = useTheme();

  function getMonthName(month) {
    const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return months[month - 1];
  }
  const yearlyData = transactionByYear?.response?.sort((a, b) => a?.month - b?.month);

  return (
    <>
      <Grid container xs={12} position={"relative"}>
        <Grid item xs={12}>
          <CommonDatePicker
            date={date}
            setDate={setDate}
            yearly
            handelNextDate={handelNextDate}
            handelPrevDate={handelPrevDate}
          />
        </Grid>
        <Grid item xs={12} className={classes.scrollebleItem}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell></TableCell>
                  <TableCell align="right" className={classes.cellStyle}>Income (Credit)</TableCell>
                  <TableCell align="right" className={classes.cellStyle}>Expense (Debit)</TableCell>
                  <TableCell align="right" className={classes.cellStyle}>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cellStyle} sx={{ border: 0 }}>C/F</TableCell>
                  <TableCell className={classes.cellStyle} sx={{ border: 0 }}></TableCell>
                  <TableCell className={classes.cellStyle} sx={{ border: 0 }}></TableCell>
                  <TableCell align="right" className={classes.cellStyle} sx={{ border: 0 }}>{transactionByYear?.carryForwardAmount ? divideByHundred(transactionByYear?.carryForwardAmount) : "0.00"}</TableCell>
                </TableRow>
                {yearlyData?.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" className={classes.cellStyle}  >
                      {getMonthName(data?.month)}
                    </TableCell>
                    <TableCell align="right" className={classes.cellStyle} >{data?.credit ? divideByHundred(data?.credit) : "0.00"}</TableCell>
                    <TableCell align="right" className={classes.cellStyle} >{data?.debit ? divideByHundred(data?.debit) : "0.00"}</TableCell>
                    <TableCell align="right" className={classes.cellStyle} >{data?.totalBalance ? divideByHundred(data?.totalBalance) : "0.00"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box height={"50px"} >

          </Box>
        </Grid>
        <Grid item xs={12} position={"absolute"} bottom={0} right={4}>
          <Box>
            <Fab size='medium' color="primary" aria-label="add"
              sx={{ boxShadow: "none", color: theme.palette.bgLightWhite.main }}
              onClick={() => downloadYearlyPdf(transactionByYear, searchData)}
            >
              <PictureAsPdfIcon fontSize="medium" />
            </Fab>
          </Box>
        </Grid>
      </Grid >
    </>
  );
};

export default Yearly;
