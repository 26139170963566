import { createTheme } from "@mui/material/styles";

// Create a theme instance.

export const defaultPrimaryColor = "#136d6f";
export const defaultWarningColor = "#FFBE5E";
export const defaultInfoColor = "#2facb0";
export const defaultbgGreenLight = "#a8dadd";
export const defaultBgLightWhite = "#FFFFFF";
export const defaultBgDarkGreen = "#3b8a51";
export const defaultBgLightGrey = "#ebeced"
export const defaultBgInfo = "#e8e8ff"
export const defaultBgDangerColor = "#FF0000"
export const defaultBgLightRed = "#F14336";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: defaultPrimaryColor
    },
    warning: {
      main: defaultWarningColor,
    },
    info: {
      main: defaultInfoColor,
    },
    bgLightGreen: {
      main: defaultbgGreenLight,
    },
    bgLightWhite: {
      main: defaultBgLightWhite,
    },
    bgDarkGreen: {
      main: defaultBgDarkGreen,
    },
    bgLightGrey: {
      main: defaultBgLightGrey
    },
    bgInfo: {
      main: defaultBgInfo
    },
    bgDanger: {
      main: defaultBgDangerColor
    },
    bgLightRed: {
      main: defaultBgLightRed
    }
  }
})