import { useTheme } from '@emotion/react';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CommonDrawer = ({ title, onClose, content, open, Transition, isDelete, onDelete, isPdf }) => {
  const theme = useTheme();

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        sx={{ maxWidth: "500px", margin: "auto", }}
      >
        <AppBar sx={{ position: 'relative' }} >
          <Toolbar variant='dense'>
            <IconButton
              edge="start"
              color={theme.palette.bgInfo.main}
              onClick={onClose}
              aria-label="close"
            >
              <ArrowBackIcon sx={{ color: theme.palette.bgInfo.main }} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: theme.palette.bgInfo.main }} variant="h6" component="div">
              {title}
            </Typography>
            {isDelete &&
              <IconButton
                edge="start"
                color={theme.palette.bgInfo.main}
                onClick={onDelete}
                aria-label="close"
              >
                <DeleteIcon sx={{ color: theme.palette.bgInfo.main }} />
              </IconButton>
            }
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: isPdf ? 0 : "10px", }}>
          {content}
        </DialogContent>
      </Dialog >
    </>
  )
}

export default CommonDrawer
