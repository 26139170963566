import React from "react";
import { Box, Checkbox, Fab, Grid, TextField, Typography, useTheme, withStyles } from "@mui/material";
import CommonDatePicker from "../Common/CommonDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { makeStyles } from "tss-react/mui";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { calculateBalance, calculateTotal } from "../../Utils/handler";
import { useAppContext } from "../../Context/Context";
import { divideByHundred, permissions, preventSelectText } from "../../Utils/enum";

const useStyles = makeStyles()((theme) => {
  return {
    gridRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    scrollebleItem: {
      maxHeight: `calc(0.5 * ${window?.innerHeight}px)`,
      minHeight: `calc(0.77   * ${window?.innerHeight}px)`,
      overflowY: "auto",
      width: "100%",
    },
    gridWidth: {
      maxWidth: "99% !important",
      width: "100%"
    },
    rootCheckBox: {
      // backgroundColor: 'white',
      // borderRadius: 5,
      ".MuiSvgIcon-root": {
        // backgroundColor: "white",
        // paddingTop: 0,
        // paddingBottom: 0,
      },
    },
  };
});


const Monthly = ({
  date,
  setDate,
  handelNextDate,
  handelPrevDate,
  transactionByMonth,
  handlePressEnd,
  handlePressStartMonthly,
  handleDateClickMonthly,
  selectedDates,
  handleSelectMontlyDate,
  downloadMonthlyPdf,
  searchData,
  loading
}) => {
  const { classes } = useStyles();
  const { user } = useAppContext();
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);

  const groupedData = transactionByMonth?.response?.length > 0 && transactionByMonth?.response?.reduce((acc, entry) => {
    const date = dayjs(entry?.date).format("DD MMMM YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(entry);
    return acc;
  }, {});

  const aggregatedData = Object.entries(groupedData).map(([date, entries]) => {
    const totalCredit = calculateTotal(entries.flatMap(entry => entry.creditDetails), 'credit');
    const totalDebit = calculateTotal(entries.flatMap(entry => entry.debitDetails), 'debit');
    const balance = entries[0]?.balance
    console.log(entries, transactionByMonth?.response, "aggregatedData")
    return {
      date,
      totalCredit,
      totalDebit,
      balance,
      entries
    };
  });


  const totalCreditSum = aggregatedData.reduce((acc, entry) => {
    const totalCreditValue = parseFloat(entry.totalCredit.replace('', ''));
    return acc + totalCreditValue;
  }, 0);

  const totalDebitSum = aggregatedData.reduce((acc, entry) => {
    const totalDebitValue = parseFloat(entry.totalDebit.replace('', ''));
    return acc + totalDebitValue;
  }, 0);
  return (
    <>
      <Grid item container xs={12} spacing={0} position={"relative"}>
        <Grid item xs={12}>
          <CommonDatePicker
            date={date}
            setDate={setDate}
            monthly
            handelNextDate={handelNextDate}
            handelPrevDate={handelPrevDate}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ position: "absolute", zIndex: -1, top: 0 }}>
              <DesktopDatePicker
                className={classes.dateBox}
                inputFormat="MM/DD/YYYY"
                value={dayjs(date?.month)}
                onChange={(newValue) => {
                  setDate({ ...date, month: newValue });
                }}
                renderInput={(params) => <TextField {...params} />}
                open={isOpen}
                closeOnSelect
                formatDensity="dense"
                views={["month", "year"]}
                sx={{ visibility: "hidden" }}
                onClose={() => setIsOpen((isOpen) => !isOpen)}
              />
            </div>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} className={classes.scrollebleItem} mt={1}>
          <Grid item xs={12} className={classes.gridWidth}>
            <Box
              bgcolor={theme.palette.bgLightGreen.main}
              p={0.5}
              borderRadius={2}
              padding={1}
              display={"flex"}
              flexDirection={"column"}
              gap={0.5}
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="body2" fontSize={"14px"} >
                  Total Income (Credit) ={totalCreditSum ? divideByHundred(totalCreditSum) : "0.00"}
                </Typography>
                <Typography variant="body2" fontSize={"14px"} textAlign={"end"}>
                  Total Expense (Debit) = {totalDebitSum ? divideByHundred(totalDebitSum) : "0.00"}
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="body2" fontSize={"14px"}>
                  C/F = {transactionByMonth?.carryForwardAmount ? `${divideByHundred(transactionByMonth?.carryForwardAmount)}` : "0.00"}
                </Typography>
                <Typography variant="body2" fontSize={"14px"}>
                  Balance = {transactionByMonth?.totalBalance ? `${divideByHundred(transactionByMonth?.totalBalance)}` : "0.00"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <>
            {/* ******************************************************************************************** */}
            {aggregatedData?.map(
              ({ date, totalCredit, totalDebit, balance, entries }, index) => {
                return (
                  <div
                    key={index}
                    style={preventSelectText}
                  >
                    <Grid
                      item
                      key={date}
                      xs={12}
                      mt={1}
                      borderRadius={3}
                      className={classes.gridWidth}
                      style={{
                        paddingTop: 0,
                        border: selectedDates.includes(dayjs(new Date(date)).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z')
                          ? `1px solid ${theme.palette.info.main}`
                          : '1px solid lightGrey',
                        boxShadow: selectedDates.includes(dayjs(new Date(date)).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z')
                          ? `inset 0 0 5px ${theme.palette.info.main}`
                          : 'none',
                      }}
                    >
                      <Grid container alignItems="center" p={"0 10px 0 2px"}>
                        <Grid item xs={12}>
                          <Grid item xs={12} my={1}>
                            <Box display={'flex'} justifyContent='space-between' alignItems={'center'} textAlign={'center'}>
                              <Checkbox
                                checked={selectedDates.includes(dayjs(new Date(date)).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z')}
                                onClick={() => handleSelectMontlyDate(date)}
                                classes={{
                                  root: classes.rootCheckBox,
                                  checked: classes.checked
                                }}
                                size="small"

                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }}
                              />
                              <Typography fontWeight={550}>
                                {date.split('T')[0]}
                              </Typography>
                              <Box>

                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} display={'flex'}>
                            <Grid item xs={6}>
                              <Box textAlign={'center'}>
                                <Typography variant="body2" fontSize={'14px'}>
                                  Income (Credit)
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box textAlign={'center'}>
                                <Typography
                                  variant="body2"
                                  color={theme.palette.primary.main}
                                  fontSize={'14px'}
                                >
                                  Expense (Debit)
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} display={'flex'} gap={2} mx={1.5}>
                            <Grid
                              item
                              xs={6}
                              display={'flex'}
                              flexDirection={'column'}
                              gap={0.5}
                              mt={1}
                            >
                              {entries
                                .flatMap((entry) => entry.creditDetails)
                                .map((creditDetail, idx) => (
                                  <Box
                                    key={idx}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                  >
                                    <Typography component={Box} variant="body2" fontSize={12} textTransform={'capitalize'} width={"115px"} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }}>{`${creditDetail?.title}`}</Typography>
                                    <Typography
                                      variant="body2"
                                      fontSize={13}
                                      fontWeight={500}
                                    >
                                      {creditDetail?.amount ? divideByHundred(creditDetail?.amount) : "0.00"}
                                    </Typography>
                                  </Box>
                                ))}
                              <Box display={'flex'} justifyContent={'flex-end'}>
                                <Typography
                                  color={theme.palette.bgDarkGreen.main}
                                  fontWeight={600}
                                  fontSize={15}
                                >
                                  {totalCredit ? divideByHundred(totalCredit) : "0.00"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              display={'flex'}
                              flexDirection={'column'}
                              gap={0.5}
                              mt={1}
                            >
                              {entries
                                .flatMap((entry) => entry.debitDetails)
                                .map((debitDetail, idx) => (
                                  <Box
                                    key={idx}
                                    display={'flex'}
                                    gap={1}
                                    justifyContent={'space-between'}
                                  >
                                    <Typography component={Box} variant="body2" fontSize={12} color={'primary'} textTransform={'capitalize'} width={"115px"} sx={{ wordBreak: "break-word", wordWrap: 'break-word' }}>{`${debitDetail?.title}`}</Typography>
                                    <Typography
                                      variant="body2"
                                      fontSize={13}
                                      fontWeight={500}
                                      color={'primary'}
                                    >
                                      {debitDetail?.amount ? divideByHundred(debitDetail?.amount) : "0.00"}
                                    </Typography>
                                  </Box>
                                ))}
                              <Box display={'flex'} justifyContent={'flex-end'}>
                                <Typography
                                  color={theme.palette.bgDanger.main}
                                  fontWeight={600}
                                  fontSize={15}
                                >
                                  {totalDebit ? divideByHundred(totalDebit) : "0.00"}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} mt={1} mx={1}>
                            <Box display={'flex'} justifyContent={'flex-end'}>
                              <Typography fontWeight={550}>
                                Balance = {balance ? divideByHundred(balance) : "0.00"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )
              }
            )}
            {/* ******************************************************************************************** */}
          </>
          <Box height={"50px"} >
          </Box>
        </Grid>

        <Grid item xs={12} position={"absolute"} bottom={0} right={4}>
          <Box>
            <Fab size="medium" color="primary" aria-label="add" sx={{ boxShadow: "none", color: theme.palette.bgLightWhite.main }} onClick={() => searchData && downloadMonthlyPdf(transactionByMonth?.totalBalance?.toFixed(2), searchData)}>
              <PictureAsPdfIcon fontSize="medium" />
            </Fab>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Monthly;
