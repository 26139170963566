import { Grid, IconButton, } from '@mui/material'
import React from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppContext } from '../../Context/Context';
import { divideByHundred, permissions } from '../../Utils/enum';
import { useTheme } from '@emotion/react';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
const DailyTopComponent = ({ modalOpenTop, handleClearTopModel, setIsEdit, setData, setIsOpen, setModalOpenTop, setIsEntryDelete, setIsAddUpdateTransactionModal, setIsOpenAgainstEntry, setIsCheckClick, isCheckClick, selectedItems, setDefaultFromName, setTransactionType }) => {
  const { user, setIsMergeNameMenuOpen } = useAppContext();
  const theme = useTheme()

  return (
    <>
      <div>
        {modalOpenTop && (
          <div
            style={{
              position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', backgroundColor: 'white',
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)', zIndex: 9999, width: "100%",
            }}
          >
            {/* Your popup content */}
            <Grid container py={0.5} xs={12}>
              <Grid item xs={2}>
                <IconButton onClick={handleClearTopModel}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                {((user?.permissions?.includes(permissions.isAgainst)) || (user?.userType === 0)) && selectedItems?.every(item => item.transactionType === 0) &&
                  < Grid item xs={2} textAlign={"center"}>
                    <IconButton onClick={() => { setData(selectedItems?.[0]); setModalOpenTop(false); setIsOpenAgainstEntry(true); setIsCheckClick({ ...isCheckClick, against: true }) }}>
                      <PublishedWithChangesIcon />
                    </IconButton>
                  </Grid>
                }
                {user?.permissions?.includes(permissions.create) &&
                  <Grid item xs={2} textAlign={"center"}>
                    <IconButton onClick={() => { setIsOpen(true); setIsCheckClick({ ...isCheckClick, copy: true }) }}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Grid>
                }
                {(user?.permissions?.includes(permissions.update) || user?.permissions?.includes(permissions?.isVoucher)) && selectedItems?.length == 1 &&
                  <Grid item xs={2} textAlign={"center"}>
                    <IconButton onClick={() => { setData({ ...selectedItems?.[0], formattedAmount: divideByHundred(selectedItems[0]?.amount) }); setTransactionType(selectedItems?.[0]?.transactionType); setIsEdit(true); setModalOpenTop(false); setIsAddUpdateTransactionModal(true); setIsCheckClick({ ...isCheckClick, edit: true }); }}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                }
                {user?.permissions?.includes(permissions.delete) &&
                  <Grid item xs={2} textAlign={"center"}>
                    <IconButton IconButton onClick={() => { setIsEntryDelete(true) }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                }
                {selectedItems?.length == 1 && user?.userType === 0 &&
                  <Grid item xs={2} textAlign={"center"}>
                    <IconButton
                      onClick={() => {
                        setIsMergeNameMenuOpen(true);
                        setModalOpenTop(false);
                        setDefaultFromName(selectedItems?.[0])
                      }}
                    >
                      <CompareArrowsIcon />
                    </IconButton>
                  </Grid>
                }
              </Grid>
            </Grid>
          </div>
        )}
      </div >
    </>
  )
}

export default DailyTopComponent
