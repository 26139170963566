import React from "react";
import { Box, Button, Grid, IconButton, TextField, styled } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import  { BUCKET_URL } from "../Api/Axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@emotion/react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const useStyles = makeStyles()((theme) => {
  return {
    datePicker: {
      borderBottom: "2px solid #5D5FEF",
      outline: "none",
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
  };
});

const CreateCompanyModal = ({
  data,
  handleChange,
  _getAllCompanies,
  isCompanyEdit,
  handleDeleteFile,
  handleUpload,
  onSubmit,
  error,
  setData
}) => {

  const theme = useTheme()

  return (
    <>
      <Grid item container spacing={2} sx={{ scroll: "auto" }}>
        <Grid item xs={12} >
          <TextField
            label="Name"
            variant="filled"
            size="small"
            name="name"
            type="text"
            value={data?.name || ''}
            onChange={(e) => handleChange(e)}
            focused
            fullWidth
            error={error['name'] !== undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Short Name"
            variant="filled"
            size="small"
            name="shortName"
            value={data?.shortName}
            type="text"
            onChange={(e) => handleChange(e)}
            focused
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Opening Balance"
            variant="filled"
            size="small"
            name="openingBalance"
            value={data?.formattedAmount}
            type="text"
            onChange={(e) => handleChange(e)}
            focused
            fullWidth
            inputProps={{ pattern: "[0-9]*" }}
            error={error['openingBalanceValidate'] !== undefined}
            helperText={error["openingBalanceValidate"]}
          />
        </Grid>
        <Grid item xs={12} display={"flex"} alignItems={"center"} mt={2.5}>
          <Grid item xs={6}>
            <Box width={'100%'} display={"flex"} alignItems={"center"} >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                startIcon={<CloudUploadIcon style={{ color: theme.palette.bgLightWhite.main }} />}
                name="companyLogo"
                onChange={(e) => handleUpload(e?.target?.files, "company")}
                onClick={(e) => e.target.value = null}
              >
                Upload Logo
                <VisuallyHiddenInput type="file" name="image" accept='image/*' />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {data?.companyLogo &&
              <Box display={'flex'} gap={1} justifyContent={'center'}>
                <Box
                  component={'img'}
                  sx={{ width: '60%', height: 60, borderRadius: 2, cursor: "pointer", border: `1px dashed ${theme.palette.primary.main}` }}
                  src={BUCKET_URL + data?.companyLogo}
                  alt={data?.companyLogo ? data?.companyLogo : "/"}
                />
                <IconButton color="error" onClick={(e) => { handleDeleteFile('company') }}>
                  <DeleteIcon />
                </IconButton>
              </Box>}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"center"} mt={5}>
            <Button variant="contained" onClick={onSubmit}>
              {isCompanyEdit ? "Update" : "Create"} Company
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateCompanyModal;