import { Drawer, Grid, IconButton } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppContext } from "../../Context/Context";
import { permissions } from "../../Utils/enum";

const MonthlyUpperModal = ({
  monthlyModalOpen,
  setMonthlyModalOpen,
  monthlyToggleDrawerTop,
  deleteTransactionMonthlyByDate,
  setSelectedDates
}) => {
  const { user } = useAppContext();
  return (
    <>
      {monthlyModalOpen && <div
        style={{
          position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', backgroundColor: 'white',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)', zIndex: 9999, width: "100%",
        }}
      >
        <Grid container py={0.5}>
          <Grid item xs={2}>
            <IconButton onClick={monthlyToggleDrawerTop}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={10}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            {user?.permissions?.includes(permissions.delete) &&
              <Grid item xs={2} textAlign={"center"}>
                <IconButton IconButton
                  onClick={deleteTransactionMonthlyByDate}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            }
          </Grid>
        </Grid>
      </div >}
    </>
  );
};

export default MonthlyUpperModal;