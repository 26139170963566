import { AppBar, Avatar, Box, Button, Chip, Dialog, DialogContent, Fab, Grid, IconButton, Toolbar, Typography, useTheme } from '@mui/material'
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import { useAppContext } from '../Context/Context';

const ManageProfileModal = ({ handlecreateProfileCloseModal, manageProfileData,
  setProfileData, setIsProfileEdit, setSelectedCompanies, handleChangePasswordCloseModal }) => {
  const theme = useTheme()
  const { user } = useAppContext();

  return (
    <>
      <Grid container height={"600px"} sx={{ overflow: "auto" }}>
        <Grid item xs={12} pr={1}>
          {manageProfileData?.length > 0 && manageProfileData?.map((profile => (
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1} mt={1} >
              <Grid item container onClick={() => { handlecreateProfileCloseModal(); setProfileData(profile); setSelectedCompanies(profile?.companyList); setIsProfileEdit(true) }} sx={{ cursor: "pointer" }}>
                <Grid item xs={2}>
                  <Avatar src="/static/images/avatar/2.jpg" alt={profile?.fullName} width={"50px"} height={"50px"} style={{ borderRadius: "50%" }} sx={{ bgcolor: theme.palette.primary.main }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' fontWeight={500} textTransform={"capitalize"}>{profile?.fullName}</Typography>
                  <Typography variant='body2' fontWeight={400} fontSize={"12px"} color={theme.palette.primary.main}>{profile?.userType === 0 ? "Admin" : "Profile"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Chip label="Change Password" size='small' variant='filled' onClick={() => { handleChangePasswordCloseModal(); setProfileData(profile) }} />
              </Grid>
            </Grid>
          )))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {user?.permissions?.includes(1) &&
          <Box
            position={"absolute"}
            right={0}
            marginRight={2}
            bottom={0}
            marginBottom={2}
          >
            <Fab
              size="medium"
              aria-label="add"
              color="primary"
              onClick={handlecreateProfileCloseModal}
              sx={{ color: theme.palette.bgLightWhite.main }}
            >
              <AddIcon fontSize="medium" />
            </Fab>
          </Box>
        }
      </Grid>
    </>
  )
}

export default ManageProfileModal
