import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, IconButton, Menu, MenuItem, useTheme, Grid } from "@mui/material";
import { useAppContext } from "../Context/Context";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router-dom';
import { BUCKET_URL } from "../Api/Axios";
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles()((theme) => {
  return {
    gridItem: {
      "& .MuiGrid-item": {
        padding: "0px !important",
      },
    },
    gridContainer: {
      "& .MuiGrid-container": {
        margin: "0px !important",
      },
    },
  };
});

const Header = ({ children }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const {
    logout,
    user,
    companyList,
    handleChangeCompany,
    selectedCompany,
    setIsProfileMenuOpen,
    setIsCompanyMenuOpen,
    setIsMergeNameMenuOpen,
    setIsSearch,
  } = useAppContext();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSearch = () => {
    setIsSearch(true);
  };


  return (
    <>
      {location?.pathname === "/login" || location?.pathname === "/singup" ? (
        <>{children}</>
      ) : (
        <Box Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{ bgcolor: theme.palette.info.main, boxShadow: "0" }}
          >
            <Toolbar
              variant="dense"
              sx={{
                paddingLeft: "11px !important",
                paddingRight: "6px !important",
              }}
            >
              <Grid container alignItems={"center"} gap={2.5} mt={1}>
                {companyList?.length > 0 && (
                  <Grid item xs={1}>
                    <Box sx={{ flexGrow: 0 }}>
                      <>
                        <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu} size='small'>
                          <Avatar alt={selectedCompany?.name?.toUpperCase()} src={BUCKET_URL + selectedCompany?.companyLogo} sx={{ bgcolor: theme.palette.primary.main, height: '40px', width: '40px', backgroundColor: theme.palette.bgLightGreen.main }} />
                        </IconButton>
                        <Menu
                          sx={{
                            mt: "45px",
                            height: "280px",
                            width: "400px",
                            padding: 0,
                          }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {companyList?.map((company, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  height: "60px",
                                  width: "260px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={handleCloseUserMenu}
                              >
                                <MenuItem
                                  key={i}
                                  onClick={() => handleChangeCompany(company)}
                                  sx={{ width: "100%" }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1.5}
                                  >
                                    <Grid item xs={2}>
                                      <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu} size='small'>
                                        <Avatar srcSet={BUCKET_URL + company?.companyLogo} src={BUCKET_URL + company?.companyLogo} alt={company?.name?.toUpperCase()} width={"30px"} height={"30px"} />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Box width={"180px"}>
                                        <Typography
                                          variant="body1"
                                          fontWeight={500}
                                          fontSize={14}
                                          textTransform={"capitalize"}
                                          sx={{ textWrap: "wrap" }}
                                        >
                                          {company?.name}
                                        </Typography>
                                      </Box>

                                    </Grid>
                                  </Grid>
                                </MenuItem>
                              </div>
                            );
                          })}
                        </Menu>
                      </>
                    </Box>
                  </Grid>
                )}
                <Grid item>
                  <Typography
                    variant="body2"
                    fontWeight={550}
                    sx={{
                      lineHeight: "1.1rem",
                      color: theme.palette.bgLightWhite.main,
                    }}
                  >
                    Day to Day Expenses
                  </Typography>
                  <Typography variant="caption" fontWeight={500} sx={{ lineHeight: '1.1rem', color: theme.palette.bgLightGreen.main }}>
                    {user?.fullName && user?.fullName}  {selectedCompany?.name && `- ${selectedCompany?.name?.toUpperCase()}`}
                  </Typography>
                </Grid>
              </Grid>
              <Box display={"flex"} alignItems={"center"} gap={0}>
                {companyList?.length > 0 &&
                  <IconButton aria-label="Search" onClick={handleSearch}>
                    <SearchIcon
                      fontSize="medium"
                      sx={{ color: theme.palette.bgLightWhite.main }}
                    />
                  </IconButton>}
                <IconButton aria-label="Example" onClick={handleClick}>
                  <MoreVertIcon
                    fontSize="medium"
                    sx={{ color: theme.palette.bgLightWhite.main }}
                  />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  {user?.userType === 0 && (
                    <>
                      <MenuItem
                        onClick={() => {
                          setIsCompanyMenuOpen(true);
                          handleClose();
                        }}
                      >
                        Company
                      </MenuItem>
                      <Divider sx={{ margin: "0 !important" }} />
                      {companyList?.length > 0 &&
                        <>
                          <MenuItem
                            onClick={() => {
                              setIsProfileMenuOpen(true);
                              handleClose();
                            }}
                          >
                            Profiles
                          </MenuItem>
                          <Divider sx={{ margin: "0 !important" }} />
                        </>
                      }
                      {/* <MenuItem
                        onClick={() => {
                          setIsMergeNameMenuOpen(true)
                          handleClose();
                        }}
                      >
                        Merge Names
                      </MenuItem>
                      <Divider sx={{ margin: "0 !important" }} /> */}
                    </>
                  )}
                  <MenuItem
                    onClick={() => {
                      logout();
                      handleClose();
                    }}
                  >
                    Log out
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            className={cx(classes.gridItem, classes.gridContainer)}
          >
            {children}
          </Box>
        </Box>
      )}

    </>
  );
};

export default Header;
