import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { Regex } from '../Utils/regex';
import { useAppContext } from '../Context/Context';
import axios from "../Api/Axios";
import { toast } from 'react-toastify';


const ChangePasswordModal = ({ profileData, _getAllUserProfiles, setIsChangePasswordOpen }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [passwordData, setPasswordData] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const { OnUpdateError, toggleLoader } = useAppContext();

  const handleTogglePasswordVisibility = (isPassword, fieldType) => {
    if (isPassword && fieldType === "password") {
      setShowPassword(!showPassword);
    } else if (isPassword && fieldType === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword)
    }
  };

  const Validation = () => {
    let newErrors = {};
    let isValid = true;
    console.log("profileData", profileData);

    if (!passwordData?.password) {
      isValid = false;
      newErrors['password'] = `Password is required.`;
    } else if (passwordData?.password && !passwordData?.password.match(Regex.passwordRegex)) {
      isValid = false;
      newErrors['validatePassword'] = `Enter valid password.`;
    }
    if(!passwordData?.CPassword){
      isValid = false;
      newErrors['CPassword'] = `Confirm Password is required.`;
    }else if (passwordData?.password !== passwordData?.CPassword) {
      isValid = false;
      newErrors["passwordNotMatch"] = "Password and confirm password should be same."
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = async () => {
    if (Validation()) {
      const body = {
        email: profileData?.email,
        password: passwordData?.password
      };
      toggleLoader();
      await axios
        .post(`admin/forgotPassword`, body)
        .then((res) => {
          if (res?.data?.data) {
            _getAllUserProfiles();
            setIsChangePasswordOpen(false)
          }
        })
        .catch((err) => {
          toast.error(err?.message);
          OnUpdateError(err?.message);
        }).finally(() => {
          toggleLoader();
        })
    }
  };

  return (
    <>
      <Grid container spacing={2} mt={0.5}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="filled"
            size="small"
            name="email"
            value={profileData?.email}
            type="email"
            focused
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            type={showPassword ? "text" : "password"}
            label="New Password"
            size="small"
            name="password"
            value={passwordData?.password}
            onChange={(e) => handleChange(e)}
            fullWidth
            focused
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => handleTogglePasswordVisibility(true, "password")}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={errors['validatePassword']}
            error={errors['password'] !== undefined || errors['validatePassword'] !== undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            size="small"
            name="CPassword"
            value={passwordData?.CPassword}
            onChange={(e) => handleChange(e)}
            fullWidth
            focused
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => handleTogglePasswordVisibility(true, "confirmPassword")}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={errors['passwordNotMatch']}
            error={errors['CPassword'] !== undefined || errors['passwordNotMatch'] !== undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"center"} mt={5}>
            <Button variant="contained" onClick={() => { handleSubmit() }}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ChangePasswordModal
