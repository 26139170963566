import React, { createContext, useContext, useState } from "react";
import { useTheme } from "@mui/material";
import sessionStoreUtil from "../Api/LocalStorage";
import axios from "../Api/Axios"
import { toast } from "react-toastify";
import { getDeviceInfo } from "../Utils/getDeviceInfo";
import dayjs from "dayjs";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const theme = useTheme()
  const [auth_token, setAuth_token] = useState(sessionStoreUtil.getLSItem("auth_token"));
  const [value, setValue] = React.useState(0);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [loader, setLoader] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(companyList[0]);
  const [isCompanyMenuOpen, setIsCompanyMenuOpen] = useState(false)
  const [isMergeNameMenuOpen, setIsMergeNameMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const [isSearch, setIsSearch] = React.useState(false);
  const [selectedButton, setSelectedButton] = React.useState('allTime');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [deviceInfo, setDeviceInfo] = React.useState("");

  const [isOpenSearchDate, setIsOpenSearchDate] = React.useState(false);
  const [isOpenFrom, setIsOpenFrom] = useState(false);
  const [isOpenTo, setIsOpenTo] = useState(false);
  const [isOpenMonth, setIsOpenMonth] = useState(false)
  const [isOpenYear, setIsOpenYear] = useState(false)
  const [searchData, setSearchData] = useState({})
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [date, setDate] = React.useState({ days: dayjs(), month: dayjs(), year: dayjs() });
  const [filterDate, setFilterDate] = React.useState({
    month: date?.month,
    year: date?.year
  })
  const [openMonthlyPdf, setOpenMonthlyPdf] = React.useState(false);
  const [openYearlyPdf, setOpenYearlyPdf] = React.useState(false);

  const [deviceToken, setDeviceToken] = useState(
    sessionStoreUtil.getLSItem("deviceToken") ? [sessionStoreUtil.getLSItem("deviceToken")] : null
  );
  const [user, setUser] = useState(
    sessionStoreUtil.getLSItem("user") ? JSON.parse(sessionStoreUtil.getLSItem("user")) : null
  );

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [profileImagePath, setProfileImagePath] = useState(
    sessionStoreUtil.getLSItem("profileImagePath")
  );

  const updateToken = (auth) => {
    sessionStoreUtil.setLSItem("auth_token", auth);
    setAuth_token(auth);
  };

  const onUpdateUser = (userData) => {
    sessionStoreUtil.setLSItem("user", JSON.stringify(userData));
    setUser(userData);
  };
  const onUpdateUserType = (type) => {
    sessionStoreUtil.setLSItem("user_type", type);
  };

  const onUpdateProfileImagePath = (data) => {
    sessionStoreUtil.setLSItem("profileImagePath", data);
    setProfileImagePath(data);
  };

  const logout = () => {
    sessionStoreUtil.removeLSItem("user");
    sessionStoreUtil.removeLSItem("auth_token");
    setUser(null);
    setAuth_token(null);
  };
  const OnUpdateSuccess = (data) => {
    setSuccess(data);
  };
  const OnUpdateError = (data) => {
    setError(data);
  };

  const getAllCompanies = async () => {
    // toggleLoader()
    await axios
      .post("admin/company")
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.data) {
            setCompanyList(res?.data?.data?.response);
            setSelectedCompany(res?.data?.data?.response[0])
          }
        }
      })
      .catch((err) => {
        OnUpdateError(err?.data?.message);
      })
      .finally(() => {
        // toggleLoader();
      });
  };

  const _globalSearch = async () => {
    toggleLoader();
    const body = {
      // limit: 20,
      // page: 1,
      company_id: selectedCompany?._id || "",
    };
    if (value === 2) {
      body.tabValue = "year"
    }
    if (searchText) {
      body.search = searchText || ""
    }
    if (selectedTab) {
      body.entryType = selectedTab === 1 ? 0 : selectedTab === 2 ? 1 : 0
    }
    if (selectedButton === "endDate" && startDate || endDate && selectedButton === "startDate") {
      body.fromDate = startDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') || ""
      body.toDate = endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') || ""
    }
    if (selectedButton === "month" && filterDate?.month) {
      body.month = filterDate?.month.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    }
    if (selectedButton === "year" && filterDate?.year) {
      body.year = filterDate?.year.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    }
    await axios
      .post(`admin/globleSearch`, body)
      .then((res) => {
        if (res?.data?.data) {
          setSearchData(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        OnUpdateError(err?.message);
      })
      .finally(() => {
        toggleLoader();
      });
  };

  const handleChangeCompany = (item) => {
    setSelectedCompany(item)
  }
  const toggleLoader = () => {
    setLoader((prevLoader) => !prevLoader);
  };

  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  React.useEffect(() => {
    if (auth_token) {
      getAllCompanies()
    }
  }, [])
  React.useEffect(() => {
    const deviceInfo = getDeviceInfo();

    setDeviceInfo(deviceInfo)
    // Optionally, send this information to your backend
    // fetch('/api/log-device-info', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(deviceInfo)
    // });
  }, []);
  return (
    <AppContext.Provider
      value={{
        auth_token,
        user,
        loader,
        success,
        error,
        profileImagePath,
        onUpdateProfileImagePath,
        updateToken,
        onUpdateUser,
        logout,
        OnUpdateSuccess,
        OnUpdateError,
        toggleLoader,
        onUpdateUserType,
        toggleSideBar,
        getAllCompanies,
        setCompanyList,
        companyList,
        handleChangeCompany,
        selectedCompany,
        setIsProfileMenuOpen,
        isProfileMenuOpen,
        setIsCompanyMenuOpen,
        isCompanyMenuOpen,
        deviceToken,
        isMergeNameMenuOpen,
        setIsMergeNameMenuOpen,
        isSearch,
        setIsSearch,
        selectedButton,
        setSelectedButton,
        selectedTab,
        setSelectedTab,
        searchText,
        setSearchText,
        isOpenSearchDate,
        setIsOpenSearchDate,
        deviceInfo,
        isOpenFrom,
        setIsOpenFrom,
        isOpenTo,
        setIsOpenTo,
        isOpenMonth,
        setIsOpenMonth,
        setIsOpenYear,
        isOpenYear,
        _globalSearch,
        searchData,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        filterDate,
        setFilterDate,
        date,
        setDate,
        openYearlyPdf,
        setOpenYearlyPdf,
        openMonthlyPdf,
        setOpenMonthlyPdf,
        setValue,
        value
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

//Custom hook

const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return appContext;
};

export { AppContext, AppProvider, useAppContext };
