import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import { ThemeProvider } from "@emotion/react";
import { lightTheme } from "./Theme";
import Home from "./Pages/Home";
import React from "react";
import { ToastContainer } from 'react-toastify';
import { ProtectedRoutes } from "./Pages/Protected/ProtectedRoutes";
import Loader from "./Components/Loader";
import Login from "./Pages/Login";
import "./index.css"
import 'react-toastify/dist/ReactToastify.css';


function App() {
    return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Loader />
        <ThemeProvider theme={lightTheme}>
          <Layout >
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route element={<ProtectedRoutes />}>
                <Route exact path="/" element={<Home  />} />
              </Route>
            </Routes>
          </Layout>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
