import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BUCKET_URL } from '../../Api/Axios';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, useTheme } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FacebookShareCount, WhatsappShareButton } from 'react-share';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 500
};

const ModalForImagePreview = ({ handleModalForImage, openModalForImage, data, baseImage }) => {
  console.log(data?.title, baseImage, "data")
  const theme = useTheme()
  return (
    <Dialog
      open={openModalForImage}
      onClose={handleModalForImage}
      aria-labelledby="responsive-dialog-title"
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle sx={{ padding: 1 }} id="responsive-dialog-title">
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <IconButton IconButton onClick={handleModalForImage} color='error'>
            <CloseIcon />
          </IconButton>
          <Typography variant="body2">Preview</Typography>
          <WhatsappShareButton url={BUCKET_URL + data?.image} title={data?.title} separator=':- '>
            <IconButton
              edge="start"
              color={theme.palette.info.main}
              onClick={() => { }}
            >
              <WhatsAppIcon sx={{ color: theme.palette.info.main }} />
            </IconButton>
          </WhatsappShareButton>
          {/* <IconButton
            // edge="start"
            color={theme.palette.info.main}
            onClick={() => { }}
          >
            <WhatsAppIcon sx={{ color: theme.palette.info.main }} />
          </IconButton> */}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        {/* <img
          srcSet={BUCKET_URL + data?.image}
          src={BUCKET_URL + data?.image}
          alt={data?.image}
          loading="lazy"
          
        /> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '100%', // Ensures the modal content doesn't exceed viewport height
            overflow: 'hidden',
          }}
        >
          <img
            srcSet={BUCKET_URL + data?.image}
            src={BUCKET_URL + data?.image}
            alt={data?.image}
            loading="lazy"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
        {/* <DialogContentText>
          {data?.description}
        </DialogContentText> */}
      </DialogContent>
    </Dialog>
  );
}

export default ModalForImagePreview