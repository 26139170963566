import { Box } from "@mui/material";
import Header from "./Header";
import React from "react";


const Layout = ({ children }) => {

  return (
    <>
      <Box maxWidth={"500px"} margin={"auto"} position={"relative"}>
        <Header children={children} />
      </Box>
    </>
  );
};

export default Layout;