import axios from "axios";
import sessionStoreUtil from "./LocalStorage";
import { toast } from "react-toastify";

export const BASE_URL = `https://day-to-day.mitlive.cloud/`;
// export const BASE_URL = `http://192.168.1.77:4000/`;
export const BUCKET_URL = "https://jkr-file-storage.s3.ap-south-1.amazonaws.com/"

const instance = axios.create({ baseURL: BASE_URL });

const clearLocalStore = () => {
  sessionStoreUtil.removeLSItem("user");
  sessionStoreUtil.removeLSItem("auth_token");
  sessionStoreUtil.removeLSItem("menuList");
  // removeLSItem("deviceToken");
};

const onRequestSuccess = (config) => {
  const auth_token = sessionStoreUtil.getLSItem("auth_token");
  if (auth_token) config.headers["Authorization"] = auth_token;
  return config;
};

const onRequestFailure = (error) => Promise.reject(error);

const onResponseSuccess = (response) => {
  return response;
};
const onResponseFailure = (error) => {
  if (error?.response) {
    console.log("error?.response", error?.response, error?.response?.data?.message, error?.response?.data?.message === "Token has been expired!");
    if (error?.response?.status === 400 || error?.response?.status === 500 || error?.response?.status === 404 || error?.response?.status === 409) {
      toast.error(error?.response?.data?.message || "");
    } else if (error?.response?.status === 401) {
      toast.error(error?.response?.data?.message || "");
      clearLocalStore();
      window.location.reload();
    } else if ((error?.response?.status === 401 || error?.response?.status === 410) && error?.response?.data?.message === "Token has been expired!") {
      toast.error("Please login again");
      clearLocalStore();
      window.location.reload();
    } else if (error?.response?.status === 401) {
      toast.error("Something went wrong. Please login again");
      clearLocalStore();
      window.location.reload();
    }
    return Promise.reject(error.response);
  } else {
    const customMsg = "Server is taking longer time to respond, please try again later.";
    toast.error(customMsg);
    // window.location.reload(false);
  }
};

instance.interceptors.request.use(onRequestSuccess, onRequestFailure);
instance.interceptors.response.use(onResponseSuccess, onResponseFailure);

export default instance;
