export const calculateTotal = (entries) => {
  const balance = entries?.reduce((acc, entry) => acc + entry?.amount, 0);
  if (balance < 0) {
    return `-${Math.abs(balance).toFixed(2)}`;
  } else {
    return `${balance.toFixed(2)}`;
  }
};

export const calculateBalance = (entries) => {
  const totalBalance = entries.reduce((acc, entry) => acc + entry.balance, 0);
  if (totalBalance < 0) {
    return `-${Math.abs(totalBalance).toFixed(2)}`;
  } else {
    return `${totalBalance.toFixed(2)}`;
  }
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const capitalizeFirstChar = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export const capitalize = (value) => {
  if (!value) return '';
  let words = value.split(' ');

  // Capitalize the first letter of each word
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back into a single string
  return capitalizedWords.join(' ');
};