import React, { useState } from 'react'
import { Box, Chip, Divider, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import { useAppContext } from '../Context/Context';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { divideByHundred } from '../Utils/enum';

const SearchContent = ({ handleButtonClick, theme, classes, tags, allTransactions, handleClickPdf, generateHighlightedTitle, creditTransactions, debitTransactions, isOpenFrom, setIsOpenFrom, isOpenTo, setIsOpenTo, isMonthlyPdf, isOpenMonth, setIsOpenMonth, isOpenYear, setIsOpenYear, pdfUri, isYearlyPdf, bothDatesSelected, setBothDatesSelected }) => {
    const { selectedButton, setSelectedButton, searchText, selectedTab, setSelectedTab, _globalSearch, searchData, startDate, setStartDate, endDate, setEndDate, filterDate, setFilterDate } = useAppContext();
    const handleTabChange = (newValue) => {
        setSelectedTab(newValue);
        const tag = tags[newValue];
        // if (selectedButton === 'allTime') {
        //     _globalSearch(searchText || "", tag.type || 0);
        // } else if (selectedButton === "endDate") {
        //     _globalSearch(searchText || "", tag.type || 0, startDate, endDate);
        // } else if (selectedButton === "month") {
        //     _globalSearch(searchText || "", tag.type || 0, "", "", filterDate?.month);
        // } else if (selectedButton === "year") {
        //     _globalSearch(searchText || "", tag.type || 0, "", "", "", filterDate?.year);
        // } else {
        // _globalSearch(tag.type);
        // }
    };
    return (
        <>
            <Grid container  >
                <Grid item xs={12}>
                    <Box noValidate component="form" sx={{ display: 'flex', flexDirection: 'column', m: 'auto' }}>
                        {(searchText || isMonthlyPdf || isYearlyPdf) ?
                            <>
                                {(searchText || isMonthlyPdf || isYearlyPdf) &&
                                    <Grid container mb={0} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
                                            <Chip
                                                label={"All time"}
                                                onClick={() => { handleButtonClick('allTime'); setStartDate(null); setEndDate(null); setBothDatesSelected(false) }}
                                                variant={selectedButton === 'allTime' ? "filled" : "outlined"}
                                                color="primary"
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderRadius: 5,
                                                    backgroundColor: selectedButton === 'allTime' ? theme.palette.primary.main : theme.palette.common.white,
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                    fontSize: "13px !important"
                                                }}
                                            />
                                            {/* Month */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    value={filterDate?.month}
                                                    onChange={(newValue) => {
                                                        setFilterDate({ ...filterDate, month: newValue });
                                                        setSelectedButton('month');
                                                    }}
                                                    closeOnSelect
                                                    formatDensity="dense"
                                                    renderInput={(params) => <TextField {...params} size="small" />}
                                                    open={isOpenMonth}
                                                    sx={{ visibility: "hidden", width: 8, height: 0 }}
                                                    onClose={() => {
                                                        setIsOpenMonth(!isOpenMonth);
                                                        // setFilterDate({ ...filterDate, month: filterDate?.month ? filterDate?.month : dayjs() })
                                                    }}
                                                    views={["month", "year"]}
                                                />
                                            </LocalizationProvider>
                                            <Chip
                                                label={
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='subtitle1' fontSize={"13px !important"}>{filterDate?.month ? dayjs(filterDate?.month).format('MMM-YYYY') : dayjs().format('MMM-YYYY')}</Typography>
                                                    </Box>
                                                }
                                                onClick={() => { setIsOpenMonth(true); setFilterDate({ ...filterDate, month: null }); setStartDate(null); setEndDate(null); setBothDatesSelected(false) }}
                                                color="primary"
                                                sx={{
                                                    cursor: 'pointer',
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                    borderRadius: 5,
                                                    backgroundColor: selectedButton === 'month' ? theme.palette.primary.main : '',
                                                    color: selectedButton === 'month' ? theme.palette?.common.white : theme.palette.primary.main,
                                                    fontSize: "13px !important"
                                                }}
                                                variant={selectedButton === 'month' ? "filled" : "outlined"}
                                            />
                                            {/* Year */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    value={filterDate?.year}
                                                    onChange={(newValue) => {
                                                        console.log("newValue", newValue);
                                                        setFilterDate({ ...filterDate, year: newValue });
                                                        setSelectedButton('year');
                                                    }}
                                                    closeOnSelect
                                                    formatDensity="dense"
                                                    renderInput={(params) => <TextField {...params} size="small" />}
                                                    open={isOpenYear}
                                                    sx={{ visibility: "hidden", width: 8, height: 0 }}
                                                    onClose={() => {
                                                        setIsOpenYear(!isOpenYear);
                                                        // setFilterDate({ ...filterDate, year: filterDate?.year ? filterDate?.year : dayjs() })
                                                    }}
                                                    views={["year"]}
                                                />
                                            </LocalizationProvider>
                                            <Chip
                                                label={
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='subtitle1' fontSize={"13px !important"}>{filterDate?.year ? dayjs(filterDate?.year).format('YYYY') : dayjs().format('YYYY')}</Typography>
                                                    </Box>
                                                }
                                                onClick={() => { setIsOpenYear(true); setFilterDate({ ...filterDate, year: null }); setStartDate(null); setEndDate(null); setBothDatesSelected(false) }}
                                                color="primary"
                                                sx={{
                                                    cursor: 'pointer',
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                    borderRadius: 5,
                                                    backgroundColor: selectedButton === 'year' ? theme.palette.primary.main : '',
                                                    color: selectedButton === 'year' ? theme.palette?.common.white : theme.palette.primary.main,
                                                    fontSize: "13px !important"
                                                }}
                                                variant={selectedButton === 'year' ? "filled" : "outlined"}
                                            />
                                            {/* </Box> */}
                                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopDatePicker
                                                        inputFormat="MM/DD/YYYY"
                                                        value={dayjs(startDate)}
                                                        onChange={(newValue) => {
                                                            setStartDate(newValue);
                                                            setSelectedButton('startDate');
                                                            if (endDate) {
                                                                setBothDatesSelected(true)
                                                            };
                                                        }}
                                                        closeOnSelect
                                                        formatDensity="dense"
                                                        renderInput={(params) => <TextField {...params} size="small" />}
                                                        open={isOpenFrom}
                                                        sx={{ visibility: "hidden", width: 8 }}
                                                        onClose={() => {
                                                            setIsOpenFrom(!isOpenFrom);
                                                        }}
                                                        maxDate={endDate}
                                                    />
                                                </LocalizationProvider>
                                                <Chip
                                                    label={
                                                        <Box display="flex" alignItems="center" justifyContent="center">
                                                            <Typography variant='subtitle1' fontSize={"13px !important"}>{startDate ? dayjs(startDate).format('DD MMMM, YYYY') : "Start Date"}</Typography>
                                                        </Box>
                                                    }
                                                    onClick={() => { setIsOpenFrom(!isOpenFrom); setStartDate(endDate ? endDate?.subtract(1, "day") : dayjs()) }}
                                                    color="primary"
                                                    sx={{
                                                        cursor: 'pointer',
                                                        border: `1px solid ${theme.palette.primary.main}`,
                                                        borderRadius: 5,
                                                        backgroundColor: selectedButton === 'startDate' || bothDatesSelected ? theme.palette.primary.main : '',
                                                        color: selectedButton === 'startDate' || bothDatesSelected ? theme.palette?.common.white : theme.palette.primary.main,
                                                    }}
                                                    variant={selectedButton === 'startDate' || bothDatesSelected ? "filled" : "outlined"}
                                                />
                                                <Typography variant='body2' mx={0.8}>-</Typography>

                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopDatePicker
                                                        inputFormat="MM/DD/YYYY"
                                                        value={dayjs(endDate)}
                                                        onChange={(newValue) => {
                                                            setEndDate(newValue);
                                                            setSelectedButton('endDate');
                                                            if (startDate) {
                                                                setBothDatesSelected(true)
                                                            };
                                                        }}
                                                        closeOnSelect
                                                        formatDensity="dense"
                                                        renderInput={(params) => <TextField {...params} size="small" />}
                                                        open={isOpenTo}
                                                        sx={{ visibility: "hidden", width: 0 }}
                                                        onClose={() => {
                                                            setIsOpenTo(!isOpenTo);
                                                        }}
                                                        minDate={startDate}
                                                    />
                                                </LocalizationProvider>
                                                <Chip
                                                    label={
                                                        <Box display="flex" alignItems="center" justifyContent="center">
                                                            <Typography variant='subtitle1' fontSize={"13px !important"}>{endDate ? dayjs(endDate).format('DD MMMM, YYYY') : "End Date"}</Typography>
                                                        </Box>
                                                    }
                                                    onClick={() => { setIsOpenTo(!isOpenTo); setEndDate(startDate ? startDate : dayjs()); }}
                                                    color="primary"
                                                    sx={{
                                                        cursor: 'pointer',
                                                        border: `1px solid ${theme.palette.primary.main}`,
                                                        borderRadius: 5,
                                                        backgroundColor: selectedButton === 'endDate' || bothDatesSelected ? theme.palette.primary.main : '',
                                                        color: selectedButton === 'endDate' || bothDatesSelected ? theme.palette?.common.white : theme.palette.primary.main,
                                                    }}
                                                    variant={selectedButton === 'endDate' || bothDatesSelected ? "filled" : "outlined"}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid >
                                }

                                {(searchText || isMonthlyPdf || isYearlyPdf) &&
                                    <Box sx={{ display: 'flex', justifyContent: "center", gap: 1, flexWrap: 'wrap', mb: 2 }}>
                                        {tags?.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={tag.label}
                                                onClick={() => handleTabChange(index)}
                                                variant={selectedTab === index ? "filled" : "outlined"}
                                                color="primary"
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderRadius: 5,
                                                    backgroundColor: selectedTab === index ? theme.palette.primary.main : theme.palette.common.white,
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                    fontSize: "13px !important"
                                                }}
                                            />
                                        ))}
                                        <Chip
                                            icon={<DownloadIcon sx={{ fontSize: "20px", pl: 0.5 }} />}
                                            label="PDF"
                                            color="primary"
                                            variant="outlined"
                                            aria-label="pdf"
                                            sx={{ border: `1px solid ${theme.palette.primary.main}`, cursor: 'pointer', fontSize: "13px !important" }}
                                            onClick={() => handleClickPdf(allTransactions)}

                                        />
                                    </Box>
                                }

                                {(!isMonthlyPdf && !isYearlyPdf) ?
                                    <Grid item xs={12} className={classes.scrollebleItem}>
                                        {selectedTab === 0 && (
                                            <>
                                                <Grid item xs={12} mt={1}>
                                                    <Box
                                                        bgcolor={theme.palette.grey[200]}
                                                        className={classes.gridRow}
                                                        p={1}
                                                    >
                                                        <Typography variant="body1">
                                                            Balance
                                                        </Typography>
                                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                            {searchData?.totalBalance ? divideByHundred(searchData?.totalBalance) : "0.00"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box
                                                        bgcolor={theme.palette.grey[200]}
                                                        className={classes.gridRow}
                                                        p={1}
                                                    >
                                                        <Typography variant="body1">Total Income (Credit)</Typography>
                                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                            {searchData?.creditAmount ? divideByHundred(searchData?.creditAmount) : "0.00"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box
                                                        bgcolor={theme.palette.grey[200]}
                                                        className={classes.gridRow}
                                                        p={1}
                                                    >
                                                        <Typography variant="body1">Total Expense (Debit)</Typography>
                                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                            {searchData?.debitAmount ? divideByHundred(searchData?.debitAmount) : "0.00"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12} display={"flex"} flexDirection={"column"} height={`calc(0.55 * ${window?.innerHeight}px)`} overflow={"auto"}>
                                                    {allTransactions?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map(transaction => {
                                                        const highlightedTitle = generateHighlightedTitle(transaction?.title, searchText, theme, transaction?.voucherNo);
                                                        return (
                                                            <div key={transaction._id} style={{ padding: "4px 4px", marginTop: 2, marginBottom: 2 }}>
                                                                <Box className={classes.gridRow} paddingRight={1}>
                                                                    {(!isMonthlyPdf && !isYearlyPdf) &&
                                                                        <Box>
                                                                            <Typography component={Box} variant="body1" textTransform={'capitalize'} width={"215px"} sx={{ wordBreak: "break-word", wordWrap: 'break-word', lineHeight: "1rem !important" }}>
                                                                                {highlightedTitle?.map((element, index) => (
                                                                                    <React.Fragment key={index}>{element}</React.Fragment>
                                                                                ))}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                    <Typography variant="body1" color={transaction.transactionType === 0 ? theme.palette.success.light : theme.palette.error.main}>
                                                                        {transaction?.amount ? divideByHundred(transaction?.amount) : "0.00"}
                                                                    </Typography>
                                                                </Box>
                                                                <Box display={"flex"} justifyContent={"end"}>
                                                                    <Typography variant="body1">
                                                                        {dayjs(transaction?.date).format('DD-MM-YYYY')}
                                                                    </Typography>
                                                                </Box>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {selectedTab === 1 && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Box
                                                        bgcolor={theme.palette.grey[200]}
                                                        className={classes.gridRow}
                                                        p={1}
                                                    >
                                                        <Typography variant="body1">Total Income (Credit)</Typography>
                                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                            {searchData?.creditAmount ? divideByHundred(searchData?.creditAmount) : "0.00"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12} display={"flex"} flexDirection={"column"} height={`calc(0.65 * ${window?.innerHeight}px)`} overflow={"auto"}>
                                                    {creditTransactions?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map(transaction => {
                                                        const highlightedTitle = generateHighlightedTitle(transaction?.title, searchText, theme, transaction?.voucherNo);
                                                        return (
                                                            <div key={transaction._id} style={{ padding: "4px 4px", marginTop: 2, marginBottom: 2 }}>
                                                                <Box className={classes.gridRow} paddingRight={1}>
                                                                    {(!isMonthlyPdf && !isYearlyPdf) &&
                                                                        <Box>
                                                                            <Typography component={Box} variant="body1" textTransform={'capitalize'} width={"215px"} sx={{ wordBreak: "break-word", wordWrap: 'break-word', lineHeight: "1rem !important" }}>
                                                                                {highlightedTitle?.map((element, index) => (
                                                                                    <React.Fragment key={index}>{element}</React.Fragment>
                                                                                ))}
                                                                            </Typography>
                                                                            {/* <Typography variant="caption" fontWeight={'bold'}>
                                                                                {transaction?.companyDetails?.shortName || transaction?.companyDetails?.name}
                                                                            </Typography> */}
                                                                        </Box>
                                                                    }
                                                                    <Typography variant="body1" color={theme.palette.success.light}>
                                                                        {transaction?.amount?.toFixed(2)}
                                                                    </Typography>
                                                                </Box>
                                                                <Box display={"flex"} justifyContent={"end"}>
                                                                    <Typography variant="body1">
                                                                        {dayjs(transaction?.date).format('DD-MM-YYYY')}
                                                                    </Typography>
                                                                </Box>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {selectedTab === 2 && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Box
                                                        bgcolor={theme.palette.grey[200]}
                                                        className={classes.gridRow}
                                                        p={1}
                                                    >
                                                        <Typography variant="body1">Total Expense (Debit)</Typography>
                                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                            {searchData?.debitAmount ? divideByHundred(searchData?.debitAmount) : "0.00"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12} display={"flex"} flexDirection={"column"} height={`calc(0.65 * ${window?.innerHeight}px)`} overflow={"auto"}>
                                                    {debitTransactions?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map(transaction => {
                                                        const highlightedTitle = generateHighlightedTitle(transaction?.title, searchText, theme, transaction?.voucherNo);
                                                        return (
                                                            <div key={transaction._id} style={{ padding: "4px 4px", marginTop: 2, marginBottom: 2 }}>
                                                                <Box className={classes.gridRow} paddingRight={1}>
                                                                    {(!isMonthlyPdf && !isYearlyPdf) &&
                                                                        <Box>
                                                                            <Typography component={Box} variant="body1" textTransform={'capitalize'} width={"215px"} sx={{ wordBreak: "break-word", wordWrap: 'break-word', lineHeight: "1rem !important" }}>
                                                                                {highlightedTitle?.map((element, index) => (
                                                                                    <React.Fragment key={index}>{element}</React.Fragment>
                                                                                ))}
                                                                            </Typography>
                                                                            {/* <Typography variant="caption" fontWeight={'bold'}>
                                                                                {transaction?.companyDetails?.name}
                                                                            </Typography> */}
                                                                        </Box>
                                                                    }
                                                                    <Typography variant="body1" color={theme.palette.error.main}>
                                                                        {transaction?.amount ? divideByHundred(transaction?.amount) : "0.00"}
                                                                    </Typography>
                                                                </Box>
                                                                <Box display={"flex"} justifyContent={"end"}>
                                                                    <Typography variant="body1">
                                                                        {dayjs(transaction?.date).format('DD-MM-YYYY')}
                                                                    </Typography>
                                                                </Box>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    :
                                    <>
                                        <iframe src={pdfUri} width="99%" height="500px" style={{ overflowX: "hidden" }}></iframe>
                                    </>

                                }
                            </>
                            : <Box display={"flex"} justifyContent={"center"} mt={10}>Type something to search..</Box>
                        }

                    </Box>
                </Grid>
            </Grid >
        </>
    )
}

export default SearchContent
