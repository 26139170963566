export const preventSelectText = {
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    webkitTouchCallout: "none",
    webkitUserSelect: "none",
    khtmlUserSelect: "none",
    mozUserSelect: "none",
};

export const permissions = {
    create: 1,
    view: 2,
    update: 3,
    delete: 4,
    isVoucher: 5,
    isAgainst: 6
}


export const divideByHundred = (value) => {
    let formattedValue = value ? value / 100 : ""
    let newValue;
    if (value !== null && value !== undefined && !Number.isNaN(value)) {
        newValue = formattedValue
            ? formattedValue?.toLocaleString("en-IN", {
                currency: "INR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : "";
        return newValue 
    }

}