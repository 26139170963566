import { AppBar, Avatar, Box, Dialog, DialogContent, Fab, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import nodejsThumbnail from "../nodejs_thumbnail.jpg"
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppContext } from '../Context/Context';
import { useTheme } from '@emotion/react';
import { BUCKET_URL } from '../Api/Axios';

const ManageCompanyModal = ({ handlecreateCompanyCloseModal, setIsCompanyEdit, manageCompanyData, setData, isCompanyEdit }) => {
  const { user } =
    useAppContext();
  const theme = useTheme();

  return (
    <>
      <DialogContent sx={{ padding: "10px", }}>
        <Grid container height={"600px"} sx={{ overflow: "auto" }}>
          <Grid item xs={12} pr={1}>
            {manageCompanyData?.length > 0 && manageCompanyData?.map((company => (
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1} mt={1} onClick={() => { handlecreateCompanyCloseModal(); setIsCompanyEdit(true); setData(company) }} sx={{ cursor: "pointer" }}>
                <Grid item xs={2}>
                  <Avatar src={BUCKET_URL + company?.companyLogo} alt={company?.name?.toUpperCase()} width={"50px"} height={"50px"} style={{ borderRadius: "50%" }} sx={{ bgcolor: theme.palette.primary.main }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' fontWeight={500} textTransform={"capitalize"}>{company?.name}</Typography>
                  <Typography variant='body2' fontWeight={400} fontSize={"12px"} color={'primary'}>{company?.shortName}</Typography>
                </Grid>
              </Grid>
            )))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {user?.permissions?.includes(1) &&
            <Box
              position={"absolute"}
              right={0}
              marginRight={2}
              bottom={0}
              marginBottom={2}
            >
              <Fab
                size="medium"
                aria-label="add"
                color="primary"
                onClick={() => { handlecreateCompanyCloseModal() }}
                sx={{ color: theme.palette.bgLightWhite.main }}
              >
                <AddIcon fontSize="medium" />
              </Fab>
            </Box>
          }
        </Grid>
      </DialogContent >
    </>
  )
}

export default ManageCompanyModal