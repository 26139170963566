import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../logo.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "../../Api/Axios";
import { useAppContext } from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Regex } from "../../Utils/regex";

const Login = () => {
  const { OnUpdateError, toggleLoader, onUpdateUser, updateToken, auth_token, getAllCompanies } = useAppContext();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const Validation = () => {
    let newErrors = {};
    let isValid = true;

    if (!data?.Email?.trim()) {
      isValid = false;
      newErrors['Email'] = `Email is required.`;
    } else if (!data?.Email.match(Regex.emailRegex)) {
      isValid = false;
      newErrors['validateEmail'] = 'Enter a valid email.';
    }
    if (!data?.password?.trim()) {
      isValid = false;
      newErrors['password'] = 'Password is required.';
    } else if (!data?.password.match(Regex.passwordRegex)) {
      isValid = false;
      newErrors['validatePwd'] = 'Enter valid password.';
    }
    setErrors(newErrors);
    return isValid;
  };


  const handleLogin = async (e) => {
    e.preventDefault()
    if (Validation()) {
      const body = {
        email: data?.Email,
        password: data?.password,
      };
      toggleLoader();
      await axios
        .post("admin/login", body)
        .then((res) => {
          console.log(res, "resres")
          if (res?.data?.data) {
            onUpdateUser(res?.data?.data);
            updateToken(res?.data?.data?.token);
            navigate("/");
            getAllCompanies()
          }
        })
        .catch((err) => {
          OnUpdateError(err?.message);
        })
        .finally(() => {
          toggleLoader();
        });
    }
  };

  useEffect(() => {
    if (auth_token) {
      navigate("/");
    }
  }, [auth_token]);

  return (
    <>
      <Grid
        container
        xs={12}
        p={2}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Grid item xs={12}>
          {/* <Grid item xs={12}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img src={logo} alt="logo" width="30%"></img>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" textAlign={"center"}>DAILY EXPENSE</Typography>
          </Grid> */}
          <Box component="form" noValidate onSubmit={(e) => handleLogin(e)} autoComplete="off">
            <Grid item xs={12} mt={2}>
              <TextField
                label="Email"
                variant="filled"
                size="small"
                type="text"
                name="Email"
                value={data?.email}
                onChange={(e) => handleChange(e)}
                focused
                fullWidth
                autoComplete={'off'}
                autoCorrect="off"
                spellCheck='false'
                inputProps={{
                  autoComplete: 'off',
                  autoCorrect: "off",
                  spellCheck: 'false',
                }}
                helperText={errors['validateEmail']}
                error={errors['Email'] !== undefined || errors['validateEmail'] !== undefined}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                variant="filled"
                type={showPassword ? "text" : "password"}
                label="Password"
                name="password"
                size="small"
                value={data?.password}
                onChange={(e) => handleChange(e)}
                fullWidth
                focused
                autoComplete="false"
                autoCorrect="off"
                spellCheck='false'
                inputProps={{
                  autoComplete: 'new-password',
                  autoCorrect: "off",
                  spellCheck: 'false'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors['validatePwd']}
                error={errors['password'] !== undefined || errors['validatePwd'] !== undefined}
              />
            </Grid>
            <Grid item xs={12} mt={4} display={"flex"} justifyContent={"center"}>
              <Button variant="contained" type="submit">
                Login
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
