import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/Context";

export const ProtectedRoutes = () => {
  const { auth_token } = useAppContext();
  const navigate = useNavigate()

  const isAuth = auth_token ? true : false;

  useEffect(() => {
    if(!isAuth) {
      navigate("/login")
    }
  })

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};
