import React from 'react'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import NoDataFound from '../Common/noDataFound';
import DeleteIcon from '@mui/icons-material/Delete';
import { divideByHundred, permissions } from '../../Utils/enum';
import { useAppContext } from '../../Context/Context';

const AssignedDebitEntryTable = ({ handleSelectAgainstEntry, selectedAgainstAssignedIds, _addTransactionEntry, againstTransactions, error, setIsAgainstEntryDelete, setIsAgainstEntryData, selectedItems }) => {
    const theme = useTheme();
    const { user } = useAppContext();
    const sumOfAssignedAmounts = selectedAgainstAssignedIds?.reduce((total, item) => total + item?.amount, 0);
    const totalAmount = againstTransactions.assigned?.reduce((acc, ele) => acc + (ele?.amount || 0), 0);
    const isAgainst = (user?.userType === 0 || (user?.userType === 1 && user?.permissions?.includes(permissions?.isAgainst)))

    return (
        <Grid container spacing={2}>
            {!isAgainst || user?.userType === 0 &&
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Typography variant="body1" fontWeight={600} color={'primary'} >Not Assigned Entries</Typography>
                    </Box>
                    <TableContainer component={Paper} elevation={0}>
                        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: theme.palette.primary.main }}>Name</TableCell>
                                    <TableCell style={{ color: theme.palette.primary.main }} align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {againstTransactions?.notAssigned?.length > 0 ? (
                                    againstTransactions?.notAssigned
                                        .map((ele, index) => {
                                            return <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ padding: 0 }}>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Checkbox size="small" checked={selectedAgainstAssignedIds.some((selectedItem) => selectedItem._id === ele._id)} onClick={(e) => handleSelectAgainstEntry(e, ele)} />} label={ele?.title} style={{ marginLeft: 1 }} />
                                                    </FormGroup>
                                                </TableCell>
                                                <TableCell align="right">{ele?.amount ? divideByHundred(ele?.amount) : "0.00"}</TableCell>
                                            </TableRow>
                                        })) :
                                    <TableRow >
                                        <TableCell colSpan={12}>
                                            <NoDataFound elevation={0} />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant='caption' color={'error'}>{selectedAgainstAssignedIds?.length === 0 ? error['isSelectedDebitAmount'] : ""}</Typography>
                    <Typography variant='caption' color={'error'}>{selectedAgainstAssignedIds.length > 0 && againstTransactions?.remainingAmount < sumOfAssignedAmounts ? error['isBalanceExceeded'] : ""}</Typography>
                </Grid>
            }{
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Typography variant="body1" fontWeight={600} color={'primary'} >Against Entries</Typography>
                    </Box>
                    <TableContainer component={Paper} elevation={0}>
                        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: theme.palette.primary.main }}>Name</TableCell>
                                    <TableCell style={{ color: theme.palette.primary.main }} align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedItems?.length > 0 && (user?.userType === 0 || (user?.userType === 1 && user?.permissions?.includes(permissions?.isAgainst))) ? (
                                    <>
                                        {selectedItems.map((ele, index) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ padding: 0 }}>
                                                    <Typography variant='body2' ml={2}>
                                                        {ele?.title}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {ele?.amount ? divideByHundred(ele?.amount) : "0.00"}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {selectedItems?.length > 0 &&
                                            <TableRow>
                                                <TableCell align="right" colSpan={3}>
                                                    <Typography variant='body2' fontWeight="bold">
                                                        Total = {divideByHundred(selectedItems?.reduce((acc, ele) => acc + (ele?.amount || 0), 0))}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </>
                                ) :
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <NoDataFound elevation={0} />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
            {isAgainst && (user?.userType === 1 || user?.userType === 0) &&
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Typography variant="body1" fontWeight={600} color={'primary'} >Assigned Entries</Typography>
                    </Box>
                    <TableContainer component={Paper} elevation={0}>
                        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: theme.palette.primary.main }}>Name</TableCell>
                                    <TableCell style={{ color: theme.palette.primary.main }} align="right">Amount</TableCell>
                                    {user?.userType === 0 && <TableCell style={{ color: theme.palette.primary.main }} align="right" width={"80px"}>Action</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {againstTransactions?.assigned?.length > 0 && (user?.userType === 0 || (user?.userType === 1 && user?.permissions?.includes(permissions?.isAgainst))) ? (
                                    <>
                                        {againstTransactions.assigned.map((ele, index) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ padding: 0 }}>
                                                    <Typography variant='body2' ml={2}>
                                                        {ele?.title}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {ele?.amount ? divideByHundred(ele?.amount): "0.00"}
                                                </TableCell>
                                                {user?.userType === 0 &&
                                                    <TableCell width={"80px"}>
                                                        <Box display={"flex"} justifyContent={"end"} alignItems={"center"} gap={1.5}>
                                                            <IconButton
                                                                edge="start"
                                                                color={theme.palette.error.main}
                                                                onClick={() => { setIsAgainstEntryDelete(true); setIsAgainstEntryData(ele); }}
                                                                aria-label="close"
                                                                sx={{ padding: 0.2 }}
                                                            >
                                                                <DeleteIcon sx={{ color: theme.palette.error.main }} />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                        {totalAmount &&
                                            <TableRow>
                                                <TableCell align="right" colSpan={3}>
                                                    <Typography variant='body2' fontWeight="bold">
                                                        Total = {totalAmount ? divideByHundred(totalAmount): "0.00"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </>
                                ) :
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <NoDataFound elevation={0} />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
            {!isAgainst || user?.userType === 0 &&
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button variant="contained" onClick={_addTransactionEntry}>Submit</Button>
                    </Box>
                </Grid>
            }
        </Grid>
    )
}

export default AssignedDebitEntryTable